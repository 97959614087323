export const GenerateUniqueReferralCode: any = (existingCodes: any) => {
  const prefix = existingCodes;
  const length = 7 - prefix.length;

  let referralCode = prefix;
  for (let i = 0; i < length; i++) {
    referralCode += Math.floor(Math.random() * 10);
  }

  // Ensure the generated code is unique
  if (existingCodes.includes(referralCode)) {
    return GenerateUniqueReferralCode(existingCodes);
  }

  return referralCode;
};
