import React, { useEffect, useState } from "react";
import Image from "../ui-components/Image";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { usePageData } from "visi/provider/PageDataProvider";

type Props = {
  type?: any;
  text?: string;
  icon?: string;
  url?: string;
  className?: string;
  onClick?: any;
  hoverIcon?: any;
};

export default function AsideElement({
  type,
  text,
  icon,
  url,
  className,
  onClick,
  hoverIcon,
}: Props) {
  const route = useLocation();

  return (
    <>
      {type === "button" ? (
        <div
          onClick={() => {
            onClick();
          }}
          className={`${className} px-4 cursor-pointer group flex justify-center flex-col gap-2 py-4 rounded-md hover:bg-violet-800 active:bg-violet-800 hover:text-white`}
        >
          <p className="text-center font-inter text-[14px] font-medium group-hover:text-white">
            {text}
          </p>
        </div>
      ) : (
        <Link to={`/${url ? url : "#"}`}>
          <div
            className={`${className} ${
              route.pathname.split("/")[1] === url
                ? "text-white bg-violet-800"
                : ""
            } px-2 group flex justify-center flex-col gap-2 py-4 rounded-md hover:bg-violet-800 active:bg-violet-800 hover:text-white `}
          >
            <Image
              image={route.pathname.split("/")[1] === url ? icon : hoverIcon}
              type={"svg"}
            />
            <p
              className={`text-center font-inter text-[14px] font-medium group-hover:text-white ${
                route.pathname.split("/")[1] === url ? "text-white" : ""
              }`}
            >
              {text}
            </p>
          </div>
        </Link>
      )}
    </>
  );
}
