import React, { useEffect, useState } from "react";
import Button from "../ui-components/Button";
import Input from "../ui-components/Input";
import Image from "../ui-components/Image";
import DropDown from "../ui-components/DropDown";
import { cityJson, stateJson } from "visi/helper/dummyData";
import { toast } from "react-toastify";
import {
  ActionApproveAgent,
  ActionToGenerateRefferalCode,
} from "visi/store/client/actions";
import { useDispatch } from "react-redux";
import { GenerateUniqueReferralCode } from "visi/helper/Functions";

type Props = { title: any; details: any };

type AllowedState = "generated" | "disapproved" | "approved" | "rejected" | "";

export default function AgentsApprovalsCard({ title, details }: Props) {
  // routes
  // providers
  // redux
  const dispatch = useDispatch();
  // state
  const [status, setStatus] = useState<AllowedState>("");
  const [city, setCity] = useState("");
  const [postcode, setPostcode] = useState("");
  const [state, setState] = useState("");
  const [reason, setReason] = useState("");
  const [refferalId, setRefferalId] = useState("");
  // functions
  const CodeGenerated = () => {
    if (details?.currentAddress?.zip !== postcode)
      return toast("Postcode is not correct. Please add valid postcode!");
    if (city === "" || state === "")
      return toast("City or State is not correct!");

    setRefferalId(GenerateUniqueReferralCode(state + city));
    setStatus("generated");
  };
  const CodeApproved = async () => {
    const obj = {
      agentId: details._id,
      type: "approved", // approved disapproved  reason
      referralCode: refferalId,
    };
    await dispatch(ActionApproveAgent(obj));
    setStatus("approved");
  };

  const CodeDisApproved = async () => {
    const obj = {
      agentId: details._id,
      type: "disapproved", // approved disapproved  reason
    };
    await dispatch(ActionApproveAgent(obj));
    setStatus("disapproved");
  };

  const RejectReason = async () => {
    const obj = {
      agentId: details._id,
      type: "reason", // approved disapproved  reason
      reason: reason,
    };
    await dispatch(ActionApproveAgent(obj));
    setStatus("rejected");
  };
  // useeffects
  useEffect(() => {
    if (details?.isVerified === "approved") {
      setStatus("approved");
    }
    if (details?.isVerified === "disapproved") {
      setStatus("disapproved");
    }
    if (details?.isVerified === "rejected") {
      console.log(details, "rejectedrejectedrejectedrejected");
      setStatus("rejected");
      setReason(details.reason);
    }
    if (details?.referralCode) {
      setRefferalId(details?.referralCode);
    }
  }, [details]);

  return (
    <>
      <div className="border-[0.5px] border-gray-100 rounded-md">
        <div className="py-[30px] px-5">
          <span>
            <div className="flex justify-start flex-row">
              <p className="w-1/4 font-semibold text-[16px] text-gray-400">
                Full Name
              </p>
              <p className="w-1/4 font-semibold text-[16px] text-gray-800">
                {details.fullName ? details.fullName : "NA"}
              </p>
            </div>
            <div className="flex justify-start flex-row mt-2">
              <p className="w-1/4 font-semibold text-[16px] text-gray-400">
                Referral ID
              </p>
              <p className="w-1/4 font-semibold text-[16px] text-gray-800">
                {details.referralCode ? details.referralCode : "NA"}
              </p>
            </div>
            <div className="flex justify-start flex-row mt-2">
              <p className="w-1/4 font-semibold text-[16px] text-gray-400">
                Contact Phone
              </p>
              <p className="w-1/4 font-semibold text-[16px] text-gray-800">
                {details.phoneNo ? details.phoneNo : "NA"}
              </p>
            </div>
            <div className="flex justify-start flex-row mt-2">
              <p className="w-1/4 font-semibold text-[16px] text-gray-400">
                UPI ID
              </p>
              <p className="w-1/4 font-semibold text-[16px] text-gray-800">
                {details.upiId ? details.upiId : "NA"}
              </p>
            </div>
            <div className="flex justify-start flex-row mt-2">
              <p className="w-1/4 font-semibold text-[16px] text-gray-400">
                Pincode
              </p>
              <p className="w-1/4 font-semibold text-[16px] text-gray-800">
                {details?.currentAddress?.zip
                  ? details?.currentAddress?.zip
                  : "NA"}
              </p>
            </div>
            <div className="flex justify-start flex-row mt-2">
              <p className="w-1/4 font-semibold text-[16px] text-gray-400">
                Address
              </p>
              <p className="w-1/4 font-semibold text-[16px] text-gray-800">
                {details?.permanentAddress?.street
                  ? details?.permanentAddress?.street +
                    " " +
                    details?.permanentAddress?.city
                  : "NA"}
              </p>
            </div>
            <div className="flex justify-start flex-row mt-2">
              <p className="w-1/4 font-semibold text-[16px] text-gray-400">
                Verification Code
              </p>
              <p className="w-1/4 font-semibold text-[16px] text-gray-800">
                {details.verificationCode ? details.verificationCode : "NA"}
              </p>
            </div>
            <div className="flex justify-start flex-row mt-2">
              <p className="w-1/4 font-semibold text-[16px] text-gray-400">
                Aadhaar Number
              </p>
              <p className="w-1/4 font-semibold text-[16px] text-gray-800">
                {details.aadharNumber ? details.aadharNumber : "NA"}
              </p>
            </div>
            <div className="flex justify-start flex-row mt-2">
              <p className="w-1/4 font-semibold text-[16px] text-gray-400">
                Approval
              </p>
              <p className="w-1/4 font-semibold text-[16px] text-gray-800 capitalize">
                {details?.isVerified ? details?.isVerified : ""}
              </p>
            </div>
            <div className="flex justify-start flex-row mt-2">
              <p className="w-1/4 font-semibold text-[16px] text-gray-400">
                Aadhaar Photo
              </p>
              <div className="mt-4">
                {
                  <img
                    // className="border-[1px] border-gray-100 rounded-md shadow-md"
                    width={320}
                    height={200}
                    src={details?.aadharPhoto}
                    loading="lazy"
                    alt={"adhaar pic"}
                  />
                }
                {/* <Image image={"dummyaadhaar.png"} type={"png"} /> */}
              </div>
            </div>
          </span>

          <div
            className={`${
              status === "" ? "" : "mb-[80px]"
            } flex flex-row justify-start gap-4 items-end mt-[40px] `}
          >
            {status !== "rejected" && (
              <div className="flex flex-row gap-4 w-[70%]">
                <DropDown
                  disabled={refferalId !== "" ? true : false}
                  labelClass={"text-gray-800"}
                  id={"state"}
                  label={"state"}
                  onChange={(e: any) => {
                    setState(e.target.value);
                  }}
                >
                  <option value={""}>Choose a state</option>
                  {stateJson.map((st: any) => {
                    return (
                      <option
                        selected={city === st.code ? true : false}
                        value={st.code}
                      >
                        {st.name}
                      </option>
                    );
                  })}
                </DropDown>
                <Input
                  disabled={refferalId !== "" ? true : false}
                  labelClass={"text-gray-800"}
                  required={true}
                  inputClass={""}
                  placeholder={""}
                  label={"postcode*"}
                  onChange={(e: any) => {
                    setPostcode(e.target.value);
                  }}
                  value={postcode}
                />
                <DropDown
                  disabled={refferalId !== "" ? true : false}
                  labelClass={"text-gray-800"}
                  id={"city"}
                  label={"city"}
                  onChange={(e: any) => {
                    setCity(e.target.value);
                  }}
                >
                  <option value={""}>Choose a city</option>
                  {cityJson.map((st: any) => {
                    return (
                      <option
                        selected={city === st.code ? true : false}
                        value={st.code}
                      >
                        {st.name}
                      </option>
                    );
                  })}
                </DropDown>
              </div>
            )}
            <div className="w-[25%] flex flex-row">
              {status === "" && (
                <>
                  <Button
                    onClick={() => {
                      CodeGenerated();
                    }}
                    text={"Generate Referral  ID"}
                    className={
                      "text-white text-[14px] bg-sky-800 py-[10px] w-full"
                    }
                  ></Button>
                </>
              )}
              <div className="relative flex flex-row">
                {(status === "generated" ||
                  status === "approved" ||
                  status === "disapproved") && (
                  <div className="px-10 text-center flex justify-center items-center text-violet-800 uppercase font-semibold text-[24px]">
                    {refferalId}
                  </div>
                )}
                {status === "generated" && (
                  <>
                    <div className="w-full">
                      <Button
                        onClick={() => {
                          CodeApproved();
                        }}
                        text={"Approve"}
                        className={
                          "text-white text-[14px] bg-sky-800 py-[10px] w-full px-4"
                        }
                      ></Button>
                    </div>
                  </>
                )}
                {status === "disapproved" && (
                  <>
                    <div className="w-full flex flex-col gap-3 absolute left-[100%]">
                      <Button
                        onClick={() => {
                          CodeApproved();
                        }}
                        text={"Approve"}
                        className={
                          "text-white font-semibold text-[14px] bg-gray-400 py-[10px] w-full px-4"
                        }
                      ></Button>
                      <Button
                        onClick={() => {
                          CodeDisApproved();
                        }}
                        text={"Disapproved"}
                        className={
                          "text-white font-semibold text-[14px] bg-red-800 py-[10px] w-full  px-4"
                        }
                      ></Button>
                    </div>
                  </>
                )}
                {status === "approved" && (
                  <>
                    <div className="w-full flex flex-col gap-3 absolute left-[100%]">
                      <Button
                        onClick={() => {
                          CodeApproved();
                        }}
                        text={"Approved"}
                        className={
                          "text-white font-semibold text-[14px] bg-green-600 py-[10px] w-full  px-4"
                        }
                      ></Button>
                      <Button
                        onClick={() => {
                          CodeDisApproved();
                        }}
                        text={"Disapprove"}
                        className={
                          "text-white font-semibold text-[14px] bg-gray-400 py-[10px] w-full  px-4"
                        }
                      ></Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {(status === "" || status === "rejected") && (
            <div className="flex justify-start gap-4 items-end mt-[20px] mb-[60px]">
              <div className="w-[70%]">
                <Input
                  labelClass={"text-gray-800"}
                  required={true}
                  placeholder={""}
                  label={"Reason*"}
                  onChange={(e: any) => {
                    setReason(e.target.value);
                  }}
                  value={reason}
                />
              </div>
              <div className="w-[25%] flex flex-row">
                {status === "rejected" ? (
                  <Button
                    onClick={() => {}}
                    text={"Rejected"}
                    className={
                      "text-black-400 font-semibold text-[14px] bg-gray-100 py-[10px] w-full  px-4"
                    }
                  ></Button>
                ) : (
                  <Button
                    onClick={() => {
                      RejectReason();
                    }}
                    text={"Reject"}
                    className={
                      "text-white text-[14px] bg-red-800 py-[10px] w-full  px-4"
                    }
                  ></Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
