// import Input from '../input'
// import Checkbox from '../checkbox'

type Props = {
  label?: any;
  // tableData: any
  labelClass?: any;
  thead: any;
  tbody: any;
  actionButton?: any;
  topMargin?: any;
  rounded?: any;
  color?: any;
  bgColor?: any;
  currentPage?: any;
  totalRecord?: any;
  limit?: any;
  previousClick?: any;
  nextClick?: any;
  paginationClick?: any;
};

export default function Table({
  label,
  currentPage,
  limit,
  previousClick,
  nextClick,
  paginationClick,
  totalRecord,
  thead,
  tbody,
  labelClass,
  actionButton,
  topMargin,
  rounded,
  color,
  bgColor,
}: Props) {
  return (
    <>
      <div>
        <h4
          className={`  ${labelClass} capitalize text-lg font-semibold px-2 ${color}  ${topMargin} rounded-sm flex justify-between font-inter`}
        >
          {label}
          {actionButton}
        </h4>
        <div className="flex flex-col overflow-x-auto font-inter">
          <div className={`shadow-md sm:rounded-${rounded} ${bgColor}`}>
            <div className="inline-block min-w-full align-middle ">
              <div className="max-h-screen">
                <table className="border-collapse min-w-full p-2 table-fixed ">
                  {thead}
                  {tbody}
                </table>
                {totalRecord > 0 && (
                  <div className="flex justify-between items-center py-4">
                    <span className="text-sm text-gray-700 dark:text-gray-400">
                      Showing{" "}
                      <span className="font-semibold text-gray-900 dark:text-white">
                        {currentPage}
                      </span>{" "}
                      to{" "}
                      <span className="font-semibold text-gray-900 dark:text-white">
                        {limit > totalRecord ? totalRecord : limit}
                      </span>{" "}
                      of{" "}
                      <span className="font-semibold text-gray-900 dark:text-white">
                        {totalRecord}
                      </span>{" "}
                      Entries
                    </span>
                    <nav aria-label="Page navigation example">
                      <ul className="flex items-center -space-x-px h-10 text-base">
                        <li>
                          <div
                            onClick={() => {
                              previousClick();
                            }}
                            className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                          >
                            <span className="sr-only">Previous</span>
                            <svg
                              className="w-3 h-3 rtl:rotate-180"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 6 10"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M5 1 1 5l4 4"
                              />
                            </svg>
                          </div>
                        </li>

                        {Array(
                          totalRecord / limit === 0
                            ? totalRecord / limit
                            : Math.ceil(totalRecord / limit)
                        )
                          .fill("")
                          .map((_, index) => {
                            return (
                              <li onClick={() => paginationClick(index + 1)}>
                                <a
                                  href="#"
                                  className={`${
                                    currentPage === index + 1
                                      ? "text-gray-800 bg-violet-200 border-violet-200"
                                      : "border-gray-300"
                                  } flex items-center justify-center px-4 h-10 leading-tight border hover:bg-gray-100 hover:text-gray-700`}
                                >
                                  {index + 1}
                                </a>
                              </li>
                            );
                          })}

                        {/* <li onClick={() => paginationClick(2)}>
                          <a
                            href="#"
                            className={`${
                              currentPage === 2
                                ? "text-gray-800 bg-violet-200 border-violet-200"
                                : "border-gray-300"
                            } flex items-center justify-center px-4 h-10 leading-tight border hover:bg-gray-100 hover:text-gray-700`}
                          >
                            2
                          </a>
                        </li> */}
                        {/* {totalRecord / limit > currentPage && ( */}
                        <li>
                          <div
                            onClick={() => {
                              nextClick();
                            }}
                            className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                          >
                            <span className="sr-only">Next</span>
                            <svg
                              className="w-3 h-3 rtl:rotate-180"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 6 10"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="m1 9 4-4-4-4"
                              />
                            </svg>
                          </div>
                        </li>
                        {/* )} */}
                      </ul>
                    </nav>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Table.defaultProps = {
  topMargin: "mt-4",
  rounded: "lg",
  bgColor: "bg-white",
  // color : "bg-blue-200"
};
