import { combineReducers } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { AdminReducers } from "../client/reducers";

const rootReducer = combineReducers({
  admin: AdminReducers,
});

// const useAdminSelector = () => {
//   return useSelector((state: any) => state.admin)
// }
const useAdminSelector = () => {
  return useSelector((state: any) => state.admin.admin);
};
// const useClientSelector: TypedUseSelectorHook<RootState> = useSelector

export { rootReducer, useAdminSelector };
