import React from "react";

type Props = {
  type?: any;
  name?: any;
  id?: any;
  placeholder?: any;
  required?: any;
  value?: any;
  min?: any;
  max?: any;
  onChange?: any;
  readOnly?: any;
  label?: any;
  labelClass?: any;
  inputClass?: any;
  error?: any;
  disabled?: any;
};

export default function Input({
  type,
  name,
  id,
  placeholder,
  required,
  value,
  min,
  max,
  onChange,
  readOnly,
  label,
  labelClass,
  inputClass,
  error,
  disabled,
}: Props) {
  return (
    <>
      <div className="w-full">
        {label ? (
          <label
            className={` ${labelClass} block text-normal text-gray-700 font-semibold`}
          >
            {label}
          </label>
        ) : (
          ""
        )}
        <div className="">
          <input
            disabled={disabled}
            type={type}
            name={name}
            id={id}
            placeholder={placeholder}
            required={required}
            value={value}
            min={min}
            max={max}
            onChange={onChange}
            readOnly={readOnly}
            className={`${inputClass} ${
              label ? "mt-2" : ""
            } border border-gray-100 hover:border-red text-gray-900 text-sm rounded-md block w-full p-2.5 `}
          />
        </div>
        {error ? (
          <p className="mt-1 text-red-400 md:text-sm sm:text-[.65rem]">
            {error}
          </p>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

Input.defaultProps = {
  labelClass: "mt-2",
};
