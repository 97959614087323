import { createContext, useContext, useState } from "react";

export interface PageDataContextModel {
  currentUser?: any;
  setCurrentUser?: any;
  currentRoute?: any;
  setCurrentRoute?: any;
  headersLinks?: any;
  setHeadersLinks?: any;
  activeHeadersLinks?: any;
  setActiveHeadersLinks?: any;
}

const PageDataContext = createContext<PageDataContextModel>({
  setCurrentUser: (variables: any) => {},
  setCurrentRoute: (variables: any) => {},
  setHeadersLinks: (variables: any) => {},
  setActiveHeadersLinks: (variables: any) => {},
});

const PageDataProvider = ({ children }: any) => {
  // states
  const [currentUser, setCurrentUser] = useState<any | undefined>(undefined);
  const [currentRoute, setCurrentRoute] = useState<any | undefined>("/");
  const [headersLinks, setHeadersLinks] = useState<any | undefined>([]);
  const [activeHeadersLinks, setActiveHeadersLinks] = useState<any>("");

  const initialState: PageDataContextModel = {
    activeHeadersLinks,
    setActiveHeadersLinks,
    headersLinks,
    setHeadersLinks,
    currentRoute,
    setCurrentRoute,
    currentUser,
    setCurrentUser,
  };
  return (
    <PageDataContext.Provider value={initialState}>
      {children}
    </PageDataContext.Provider>
  );
};

const usePageData = () => {
  return useContext(PageDataContext);
};

export { usePageData, PageDataProvider };
