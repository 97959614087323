import React, { useEffect, useLayoutEffect, useState } from "react";
import Button from "../ui-components/Button";
import Input from "../ui-components/Input";
import { useNavigate } from "react-router-dom";
import { ActionUpdateAgent } from "visi/store/client/actions";
import { useDispatch } from "react-redux";
import Spinner from "../ui-components/Spinner";
import { useAdminSelector } from "visi/store/reducers";

type Props = {
  title?: string;
  details?: any;
  startEdit: any;
  actionButton: any;
};

export default function AgentsDetailedCard({
  title,
  details,
  startEdit,
  actionButton,
}: Props) {
  // routes
  const navigation = useNavigate();
  // redux
  const dispatch = useDispatch();
  const { updateAgentLoading } = useAdminSelector();
  // state
  const [fullName, setFullName] = useState<any>("");
  const [pincode, setPincode] = useState<any>("");
  const [UPIId, setUPIId] = useState<any>("");
  const [currentAddress, setCurrentAddress] = useState<any>("");
  const [currentAddress2, setCurrentAddress2] = useState<any>("");
  const [aadharNumber, setAadharNumber] = useState<any>("");
  const [aadhaarPhoto, setAadhaarPhoto] = useState<any>("");
  // function
  const EditAgentDetails = async () => {
    const address = JSON.stringify({
      street: currentAddress,
      city: currentAddress2 ? currentAddress2 : "city",
      state: "India",
      zip: pincode,
      location: {
        type: "Point",
        coordinates: [],
        formattedAddress: currentAddress + " " + currentAddress2,
        googlePlaceId: "123457",
      },
    });
    // new formdata
    const formData = new FormData();
    if (aadhaarPhoto) {
      formData.append("aadharPhoto", aadhaarPhoto);
    }
    formData.append("adharCardNumber", aadharNumber);
    formData.append("agentId", details._id);
    formData.append("permanentAddress", address);
    formData.append("fullName", fullName);
    formData.append("currentAddress", address);
    formData.append("upiId", UPIId);
    console.log(details, Array.from(formData), "formDataformData");
    try {
      await dispatch(ActionUpdateAgent(formData));
      actionButton(false);
    } catch (error) {
      console.log(error, "CreateProfileError");
    }
  };
  // useeffects
  useEffect(() => {
    setFullName(details?.fullName);
    setUPIId(details?.upiId);
    setPincode(details?.currentAddress?.zip);
    setCurrentAddress(details?.permanentAddress?.street);
    setCurrentAddress2(details?.permanentAddress?.city);
    setAadharNumber(details?.aadharNumber);
  }, [startEdit]);

  return (
    <>
      <div className="border-[0.5px] border-gray-100 rounded-b-md">
        <div className="border-b-[0.5px] border-gray-100 py-5 px-5 flex justify-between items-center">
          <p className="capitalize font-semibold text-[18px] text-gray-800">
            {title}
          </p>
          {startEdit ? (
            <div className="flex justify-end gap-3">
              <Button
                className={
                  "bg-green-400 text-white text-13 font-regular flex flex-row items-center justify-center gap-3 duration-100"
                }
                onClick={() => {
                  EditAgentDetails();
                }}
              >
                Submit
                {updateAgentLoading ? <Spinner height={20} width={20} /> : ""}
              </Button>
              <Button
                className={"bg-gray-800 text-white font-normal"}
                onClick={() => {
                  actionButton(false);
                }}
                text={"Cancel"}
              ></Button>
            </div>
          ) : (
            <Button
              className={"bg-sky-800 text-white font-normal py-2 px-2"}
              onClick={() => {
                actionButton(true);
              }}
              text={"Edit Profile"}
            ></Button>
          )}
        </div>
        <div className="py-[30px] px-5">
          <div className="flex justify-start flex-row">
            <p className="w-1/4 font-semibold text-[16px] text-gray-400">
              Full Name
            </p>
            {startEdit ? (
              <Input
                required={true}
                inputClass={"w-[30%]"}
                placeholder={"full name"}
                onChange={(e: any) => {
                  setFullName(e.target.value);
                }}
                value={fullName}
                type={"text"}
              />
            ) : (
              <p className="w-1/4 font-semibold text-[16px] text-gray-800">
                {details?.fullName ? details?.fullName : "NA"}
              </p>
            )}
          </div>

          <div className="flex justify-start flex-row mt-2">
            <p className="w-1/4 font-semibold text-[16px] text-gray-400">
              UPI ID
            </p>
            {startEdit ? (
              <Input
                required={true}
                inputClass={"w-[30%]"}
                placeholder={"upi id"}
                onChange={(e: any) => {
                  setUPIId(e.target.value);
                }}
                value={UPIId}
                type={"text"}
              />
            ) : (
              <p className="w-1/4 font-semibold text-[16px] text-gray-800">
                {details?.upiId ? details?.upiId : "NA"}
              </p>
            )}
          </div>
          <div className="flex justify-start flex-row mt-2">
            <p className="w-1/4 font-semibold text-[16px] text-gray-400">
              Pincode
            </p>
            {startEdit ? (
              <Input
                required={true}
                inputClass={"w-[30%]"}
                placeholder={"pincode"}
                onChange={(e: any) => {
                  setPincode(e.target.value);
                }}
                value={pincode}
                type={"text"}
              />
            ) : (
              <p className="w-1/4 font-semibold text-[16px] text-gray-800">
                {details?.currentAddress?.zip
                  ? details?.currentAddress?.zip
                  : "NA"}
              </p>
            )}
          </div>
          <div className="flex justify-start flex-row mt-2">
            <p className="w-1/4 font-semibold text-[16px] text-gray-400">
              Address
            </p>
            {startEdit ? (
              <Input
                required={true}
                inputClass={"w-[30%]"}
                placeholder={"Address"}
                onChange={(e: any) => {
                  setCurrentAddress(e.target.value);
                }}
                value={currentAddress}
                type={"text"}
              />
            ) : (
              <p className="w-1/4 font-semibold text-[16px] text-gray-800">
                {details?.permanentAddress?.street
                  ? details?.permanentAddress?.street +
                    " " +
                    details?.permanentAddress?.city
                  : "NA"}
              </p>
            )}
          </div>

          <div className="flex justify-start flex-row mt-2">
            <p className="w-1/4 font-semibold text-[16px] text-gray-400">
              Aadhaar Number
            </p>
            {startEdit ? (
              <Input
                required={true}
                inputClass={"w-[30%]"}
                placeholder={"aadhaar number"}
                onChange={(e: any) => {
                  setAadharNumber(e.target.value);
                }}
                value={aadharNumber}
                type={"text"}
              />
            ) : (
              <p className="w-1/4 font-semibold text-[16px] text-gray-800">
                {details?.aadharNumber ? details?.aadharNumber : "NA"}
              </p>
            )}
          </div>
          {startEdit && (
            <div className="flex justify-start flex-row mt-2">
              <p className="w-1/4 font-semibold text-[16px] text-gray-400">
                Aadhaar Proof
              </p>
              <p className="w-1/4 font-semibold text-[16px] text-gray-800 capitalize">
                <Input
                  type={"file"}
                  required={true}
                  inputClass={"w-[30%]"}
                  onChange={(e: any) => {
                    setAadhaarPhoto(e.target.files[0]);
                  }}
                />
              </p>
            </div>
          )}
          <div className="flex justify-start flex-row mt-2">
            <p className="w-1/4 font-semibold text-[16px] text-gray-400">
              Approval
            </p>
            <p className="w-1/4 font-semibold text-[16px] text-gray-800 capitalize">
              {details?.isVerified ? details?.isVerified : ""}
            </p>
          </div>
          <div className="flex justify-start flex-row mt-2">
            <p className="w-1/4 font-semibold text-[16px] text-gray-400">
              Verification Code
            </p>
            <p className="w-1/4 font-semibold text-[16px] text-gray-800">
              {details?.verificationCode ? details?.verificationCode : "NA"}
            </p>
          </div>
          <div className="flex justify-start flex-row mt-2">
            <p className="w-1/4 font-semibold text-[16px] text-gray-400">
              Referral ID
            </p>
            <p className="w-1/4 font-semibold text-[16px] text-gray-800">
              {details?.referralCode ? details?.referralCode : "NA"}
            </p>
          </div>
          <div className="flex justify-start flex-row mt-2">
            <p className="w-1/4 font-semibold text-[16px] text-gray-400">
              Contact Phone
            </p>
            <p className="w-1/4 font-semibold text-[16px] text-gray-800">
              {details?.phoneNo ? details?.phoneNo : "NA"}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
