import { createSlice } from "@reduxjs/toolkit";

import {
  ActionAdminLogin,
  ActionApproveAgent,
  ActionUpdateAgent,
  ActionToGenerateRefferalCode,
  ActionToGetAgentDetails,
  ActionsGetAgentsCards,
  ActionsGetAgentsDetails,
  ActionsGetAgentsSearchedDetails,
  ActionsGetDashboardDetails,
  ActionsGetNewAgentsDetails,
  ActionsGetShopDetails,
  ActionsGetShopDetailsSearched,
  ActionsGetUserDetails,
  ActionsGetUserDetailsSearched,
  ActionsGetAgentsPaymentList,
  ActionsUpdatePaymentEntry,
  ActionCompletePayment,
} from "../actions/AdminActions";
import { SetToken } from "visi/helper/Sessions";
import { toast } from "react-toastify";
import { AgentListData, NewAgentListData } from "visi/helper/dummyData";

interface Initials {
  loading: boolean;
  dashboardDataLoading: boolean;
  dashboardData: any;
  shopsLoading: boolean;
  shopsData: any;
  usersLoading: boolean;
  usersData: any;
  usersDataCount: any;
  shopsDataCount: any;
  agentsLoading: boolean;
  agentsList: any;
  agentsListCount: any;
  newAgentsList: any;
  newAgentsListCount: any;
  newAgentsLoading: boolean;
  agentsCardsLoading: boolean;
  agentsCards: any;
  agentDetailsLoading: boolean;
  agentDetails: any;
  refferalCodeLoading: boolean;
  refferalCode: any;
  approvalLoading: any;
  updateAgentLoading: any;
  agentPaymentListLoading: any;
  agentPaymentList: any;
  agentPaymentUpdateLoading: any;
  agentPaymentCompleteLoading: any;
}

const initialState: Initials = {
  loading: false,
  dashboardDataLoading: false,
  dashboardData: {},
  shopsLoading: false,
  shopsData: [],
  usersLoading: false,
  usersData: [],
  usersDataCount: 0,
  shopsDataCount: 0,
  agentsLoading: false,
  agentsList: [],
  agentsListCount: 0,
  newAgentsList: [],
  newAgentsListCount: 0,
  newAgentsLoading: false,
  agentsCardsLoading: false,
  agentsCards: [],
  agentDetailsLoading: false,
  agentDetails: [],
  refferalCodeLoading: false,
  refferalCode: [],
  approvalLoading: false,
  updateAgentLoading: false,
  agentPaymentListLoading: false,
  agentPaymentList: [],
  agentPaymentUpdateLoading: false,
  agentPaymentCompleteLoading: false,
};

const AdminReducer = createSlice({
  name: "AdminReducer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // *************** get agents payment list detailed *************
    builder
      // initialize request
      .addCase(ActionCompletePayment.pending, (state: any) => {
        state.agentPaymentCompleteLoading = true;
      })
      // have any response from api with status code 200
      .addCase(ActionCompletePayment.fulfilled, (state: any, action: any) => {
        state.agentPaymentCompleteLoading = false;
        if (action.payload?.responseData?.result) {
          toast("Completed payment!!!");
        }
        // console.log(
        //   JSON.stringify(action.payload?.responseData?.result),
        //   "ActionCompletePayment"
        // );
      })
      // not have any response from api
      .addCase(ActionCompletePayment.rejected, (state: any, action: any) => {
        toast("Something went wrong!");
        state.agentPaymentCompleteLoading = false;
      });

    // *************** get agents payment list detailed *************
    builder
      // initialize request
      .addCase(ActionsUpdatePaymentEntry.pending, (state: any) => {
        state.agentPaymentUpdateLoading = true;
      })
      // have any response from api with status code 200
      .addCase(
        ActionsUpdatePaymentEntry.fulfilled,
        (state: any, action: any) => {
          state.agentPaymentUpdateLoading = false;
          if (action.payload?.responseData?.result) {
            toast("Updated payment!!!");
          }
          // console.log(
          //   JSON.stringify(action.payload?.responseData?.result),
          //   "ActionsUpdatePaymentEntry"
          // );
        }
      )
      // not have any response from api
      .addCase(
        ActionsUpdatePaymentEntry.rejected,
        (state: any, action: any) => {
          toast("Something went wrong!");
          state.agentPaymentUpdateLoading = false;
        }
      );

    // *************** get agents payment list detailed *************
    builder
      // initialize request
      .addCase(ActionsGetAgentsPaymentList.pending, (state: any) => {
        state.agentPaymentListLoading = true;
      })
      // have any response from api with status code 200
      .addCase(
        ActionsGetAgentsPaymentList.fulfilled,
        (state: any, action: any) => {
          state.agentPaymentListLoading = false;
          if (action.payload?.responseData?.result) {
            state.agentPaymentList = action.payload?.responseData?.result;
          }
          // console.log(
          //   JSON.stringify(action.payload?.responseData?.result),
          //   "ActionsGetAgentsPaymentList"
          // );
        }
      )
      // not have any response from api
      .addCase(
        ActionsGetAgentsPaymentList.rejected,
        (state: any, action: any) => {
          toast("Something went wrong!");
          state.agentPaymentListLoading = false;
        }
      );

    // *************** update agents details by Id *************
    builder
      // initialize request
      .addCase(ActionUpdateAgent.pending, (state: any) => {
        state.updateAgentLoading = true;
      })
      // have any response from api with status code 200
      .addCase(ActionUpdateAgent.fulfilled, (state: any, action: any) => {
        state.updateAgentLoading = false;
        if (action.payload?.responseData?.result) {
          state.agentDetails = action.payload?.responseData?.result;
        }
        // console.log(
        //   JSON.stringify(action.payload?.responseData?.result),
        //   "ActionUpdateAgent"
        // );
      })
      // not have any response from api
      .addCase(ActionUpdateAgent.rejected, (state: any, action: any) => {
        toast("Something went wrong!");
        state.updateAgentLoading = false;
      });

    // *************** get agents details by Id *************
    builder
      // initialize request
      .addCase(ActionApproveAgent.pending, (state: any) => {
        state.approvalLoading = true;
      })
      // have any response from api with status code 200
      .addCase(ActionApproveAgent.fulfilled, (state: any, action: any) => {
        state.approvalLoading = false;
        if (action.payload?.responseData?.result) {
          state.agentDetails.isVerified =
            action.payload?.responseData?.result?.res?.isVerified;
          state.agentDetails.referralCode =
            action.payload?.responseData?.result?.res?.referralCode;
          state.agentDetails.reason =
            action.payload?.responseData?.result?.res?.reason;
        }
        // console.log(
        //   JSON.stringify(action.payload?.responseData?.result),
        //   "ActionApproveAgent"
        // );
      })
      // not have any response from api
      .addCase(ActionApproveAgent.rejected, (state: any, action: any) => {
        toast("Something went wrong!");
        state.approvalLoading = false;
      });

    // *************** get agents details by Id *************
    builder
      // initialize request
      .addCase(ActionToGenerateRefferalCode.pending, (state: any) => {
        state.refferalCodeLoading = true;
      })
      // have any response from api with status code 200
      .addCase(
        ActionToGenerateRefferalCode.fulfilled,
        (state: any, action: any) => {
          state.refferalCodeLoading = false;
          state.refferalCode = action.payload?.responseData?.result;
          console.log(
            JSON.stringify(action.payload?.responseData?.result),
            "ActionToGenerateRefferalCode"
          );
        }
      )
      // not have any response from api
      .addCase(
        ActionToGenerateRefferalCode.rejected,
        (state: any, action: any) => {
          toast("Something went wrong!");
          state.refferalCodeLoading = false;
        }
      );

    // *************** get agents details by Id *************
    builder
      // initialize request
      .addCase(ActionToGetAgentDetails.pending, (state: any) => {
        state.agentDetailsLoading = true;
      })
      // have any response from api with status code 200
      .addCase(ActionToGetAgentDetails.fulfilled, (state: any, action: any) => {
        state.agentDetailsLoading = false;
        state.agentDetails = action.payload?.responseData?.result;
        // console.log(
        //   JSON.stringify(action.payload?.responseData?.result),
        //   "ActionToGetAgentDetails"
        // );
      })
      // not have any response from api
      .addCase(ActionToGetAgentDetails.rejected, (state: any, action: any) => {
        toast("Something went wrong!");
        state.agentDetailsLoading = false;
      });

    // *************** admim with login *************
    builder
      // initialize request
      .addCase(ActionAdminLogin.pending, (state: any) => {
        state.loading = true;
      })
      // have any response from api with status code 200
      .addCase(ActionAdminLogin.fulfilled, (state: any, action: any) => {
        // console.log(
        //   JSON.stringify(action.payload?.responseData),
        //   "login api response"
        // );
        SetToken(JSON.stringify(action.payload?.responseData));
      });

    // *************** get dashboards details *************
    builder
      // initialize request
      .addCase(ActionsGetDashboardDetails.pending, (state: any) => {
        state.dashboardDataLoading = true;
      })
      // have any response from api with status code 200
      .addCase(
        ActionsGetDashboardDetails.fulfilled,
        (state: any, action: any) => {
          state.dashboardDataLoading = false;
          state.dashboardData = action.payload?.responseData?.result;
          // console.log(
          //   JSON.stringify(action.payload?.responseData?.result),
          //   "ActionsGetDashboardDetails"
          // );
        }
      )
      // not have any response from api
      .addCase(
        ActionsGetDashboardDetails.rejected,
        (state: any, action: any) => {
          toast("Something went wrong!");
          state.dashboardDataLoading = false;
        }
      );

    // *************** get shops details *************
    builder
      // initialize request
      .addCase(ActionsGetShopDetails.pending, (state: any) => {
        state.shopsLoading = true;
      })
      // have any response from api with status code 200
      .addCase(ActionsGetShopDetails.fulfilled, (state: any, action: any) => {
        state.shopsLoading = false;
        state.shopsData = action.payload?.responseData?.result;
        state.shopsDataCount = action.payload?.responseData?.totalCount;
        // console.log(
        //   JSON.stringify(action.payload?.responseData?.result),
        //   "ActionsGetShopDetails"
        // );
      })
      // not have any response from api
      .addCase(ActionsGetShopDetails.rejected, (state: any, action: any) => {
        toast("Something went wrong!");
        state.shopsLoading = false;
      });

    // *************** get shops searched details *************
    builder
      // initialize request
      .addCase(ActionsGetShopDetailsSearched.pending, (state: any) => {
        state.shopsLoading = true;
      })
      // have any response from api with status code 200
      .addCase(
        ActionsGetShopDetailsSearched.fulfilled,
        (state: any, action: any) => {
          state.shopsLoading = false;
          state.shopsData = action.payload?.responseData?.result;
          // console.log(
          //   JSON.stringify(action.payload?.responseData?.result),
          //   "ActionsGetShopDetailsSearched"
          // );
        }
      )
      // not have any response from api
      .addCase(
        ActionsGetShopDetailsSearched.rejected,
        (state: any, action: any) => {
          toast("Something went wrong!");
          state.shopsLoading = false;
        }
      );

    // *************** get users details *************
    builder
      // initialize request
      .addCase(ActionsGetUserDetails.pending, (state: any) => {
        state.usersLoading = true;
      })
      // have any response from api with status code 200
      .addCase(ActionsGetUserDetails.fulfilled, (state: any, action: any) => {
        state.usersLoading = false;
        state.usersData = action.payload?.responseData?.result;
        state.usersDataCount = action.payload?.responseData?.totalCount;
        // console.log(
        //   JSON.stringify(action.payload?.responseData?.result),
        //   "ActionsGetUserDetails"
        // );
      })
      // not have any response from api
      .addCase(ActionsGetUserDetails.rejected, (state: any, action: any) => {
        toast("Something went wrong!");
        state.usersLoading = false;
      });

    // *************** get agents details *************
    builder
      // initialize request
      .addCase(ActionsGetAgentsDetails.pending, (state: any) => {
        state.agentsLoading = true;
      })
      // have any response from api with status code 200
      .addCase(ActionsGetAgentsDetails.fulfilled, (state: any, action: any) => {
        state.agentsLoading = false;
        if (action?.payload?.responseData?.result) {
          state.agentsList = action.payload?.responseData?.result;
          state.agentsListCount = action?.payload?.responseData?.totalCount;
        }
        // console.log(action.payload, "ActionsGetAgentsDetails");
      })
      // not have any response from api
      .addCase(ActionsGetAgentsDetails.rejected, (state: any, action: any) => {
        state.agentsList = AgentListData?.responseData?.result;
        state.agentsListCount = AgentListData?.responseData?.totalCount;
        toast("Something went wrong!");
        state.agentsLoading = false;
      });

    // *************** get agents cards details *************
    builder
      // initialize request
      .addCase(ActionsGetAgentsCards.pending, (state: any) => {
        state.agentsLoading = true;
      })
      // have any response from api with status code 200
      .addCase(ActionsGetAgentsCards.fulfilled, (state: any, action: any) => {
        state.agentsCardsLoading = false;
        if (action?.payload?.responseData?.result) {
          state.agentsCards = action.payload?.responseData?.result;
        }
        // console.log(action.payload, "ActionsGetAgentsCards");
      })
      // not have any response from api
      .addCase(ActionsGetAgentsCards.rejected, (state: any, action: any) => {
        state.agentsList = AgentListData?.responseData?.result;
        state.agentsListCount = AgentListData?.responseData?.totalCount;
        toast("Something went wrong!");
        state.agentsLoading = false;
      });

    // *************** get new agents details *************
    builder
      // initialize request
      .addCase(ActionsGetNewAgentsDetails.pending, (state: any) => {
        state.newAgentsLoading = true;
      })
      // have any response from api with status code 200
      .addCase(
        ActionsGetNewAgentsDetails.fulfilled,
        (state: any, action: any) => {
          state.agentsLoading = false;
          if (action.payload) {
            state.newAgentsList = action.payload?.responseData?.result;
            state.newAgentsListCount = action.payload?.responseData?.totalCount;
          } else {
            state.newAgentsList = NewAgentListData?.responseData?.result;
            state.newAgentsListCount =
              NewAgentListData?.responseData?.totalCount;
          }
          // console.log(
          //   JSON.stringify(action.payload?.responseData?.result),
          //   "ActionsGetNewAgentsDetails"
          // );
        }
      )
      // not have any response from api
      .addCase(
        ActionsGetNewAgentsDetails.rejected,
        (state: any, action: any) => {
          state.newAgentsList = NewAgentListData?.responseData?.result;
          state.newAgentsListCount = NewAgentListData?.responseData?.totalCount;
          toast("Something went wrong!");
          state.agentsLoading = false;
        }
      );

    // *************** get users searched result details *************
    builder
      // initialize request
      .addCase(ActionsGetUserDetailsSearched.pending, (state: any) => {
        state.usersLoading = true;
      })
      // have any response from api with status code 200
      .addCase(
        ActionsGetUserDetailsSearched.fulfilled,
        (state: any, action: any) => {
          state.usersLoading = false;
          state.usersData = action.payload?.responseData?.result;
          // console.log(
          //   JSON.stringify(action.payload?.responseData?.result),
          //   "ActionsGetUserDetailsSearched"
          // );
        }
      )
      // not have any response from api
      .addCase(
        ActionsGetUserDetailsSearched.rejected,
        (state: any, action: any) => {
          toast("Something went wrong!");
          state.usersLoading = false;
        }
      );
  },
});

export default AdminReducer.reducer;
