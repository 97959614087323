import { createAsyncThunk } from "@reduxjs/toolkit";
import AdminService from "../services/AdminService";

export const ActionAdminLogin: any = createAsyncThunk(
  "AdminReducer/ActionAdminLogin",
  // naming convention (reducer/action_name)
  async (obj: any) => {
    const res = await AdminService.AdminLogin(obj);
    return res.data;
  }
);

export const ActionsGetDashboardDetails: any = createAsyncThunk(
  "AdminReducer/ActionsGetDashboardDetails",
  // naming convention (reducer/action_name)
  async (obj: any) => {
    const res = await AdminService.GetDashboardDetails(obj);
    return res.data;
  }
);

export const ActionsGetShopDetails: any = createAsyncThunk(
  "AdminReducer/ActionsGetShopDetails",
  // naming convention (reducer/action_name)
  async (obj: any) => {
    const res = await AdminService.GetShopDetails(obj);
    return res.data;
  }
);

export const ActionsGetUserDetails: any = createAsyncThunk(
  "AdminReducer/ActionsGetUserDetails",
  // naming convention (reducer/action_name)
  async (obj: any) => {
    const res = await AdminService.GetUserDetails(obj);
    return res.data;
  }
);

export const ActionsGetUserDetailsSearched: any = createAsyncThunk(
  "AdminReducer/ActionsGetUserDetailsSearched",
  // naming convention (reducer/action_name)
  async (obj: any) => {
    const res = await AdminService.GetUserDetailsSearched(obj);
    return res.data;
  }
);

export const ActionsGetShopDetailsSearched: any = createAsyncThunk(
  "AdminReducer/ActionsGetShopDetailsSearched",
  // naming convention (reducer/action_name)
  async (obj: any) => {
    const res = await AdminService.GetShopDetailsSearched(obj);
    return res.data;
  }
);

export const ActionsGetAgentsCards: any = createAsyncThunk(
  "AdminReducer/ActionsGetAgentsCards",
  // naming convention (reducer/action_name)
  async (obj: any) => {
    const res = await AdminService.GetAgentsCards(obj);
    return res.data;
  }
);

export const ActionsGetAgentsDetails: any = createAsyncThunk(
  "AdminReducer/ActionsGetAgentsDetails",
  // naming convention (reducer/action_name)
  async (obj: any) => {
    const res = await AdminService.GetAgentsDetails(obj);
    return res.data;
  }
);

export const ActionsGetAgentsSearchedDetails: any = createAsyncThunk(
  "AdminReducer/ActionsGetAgentsSearchedDetails",
  // naming convention (reducer/action_name)
  async (obj: any) => {
    const res = await AdminService.GetAgentsSearchedDetails(obj);
    return res.data;
  }
);

export const ActionsGetNewAgentsDetails: any = createAsyncThunk(
  "AdminReducer/ActionsGetNewAgentsDetails",
  // naming convention (reducer/action_name)
  async (obj: any) => {
    const res = await AdminService.GetNewAgentsDetails(obj);
    return res.data;
  }
);

export const ActionToGetAgentDetails: any = createAsyncThunk(
  "AdminReducer/ActionToGetAgentDetails",
  // naming convention (reducer/action_name)
  async (obj: any) => {
    const res = await AdminService.ToGetAgentDetails(obj);
    return res.data;
  }
);

export const ActionToGenerateRefferalCode: any = createAsyncThunk(
  "AdminReducer/ActionToGenerateRefferalCode",
  // naming convention (reducer/action_name)
  async (obj: any) => {
    const res = await AdminService.ToGenerateRefferalCode(obj);
    return res.data;
  }
);

export const ActionApproveAgent: any = createAsyncThunk(
  "AdminReducer/ActionApproveAgent",
  // naming convention (reducer/action_name)
  async (obj: any) => {
    const res = await AdminService.ApproveAgent(obj);
    return res.data;
  }
);

export const ActionUpdateAgent: any = createAsyncThunk(
  "LoginUserReducer/ActionUpdateAgent",
  // naming convention (reducer/action_name)
  async (obj: any) => {
    const res = await AdminService.UpdateAgent(obj);
    return res.data;
  }
);

export const ActionsGetAgentsPaymentList: any = createAsyncThunk(
  "LoginUserReducer/ActionsGetAgentsPaymentList",
  // naming convention (reducer/action_name)
  async (obj: any) => {
    const res = await AdminService.GetAgentsPaymentList(obj);
    return res.data;
  }
);

export const ActionsUpdatePaymentEntry: any = createAsyncThunk(
  "LoginUserReducer/ActionsUpdatePaymentEntry",
  // naming convention (reducer/action_name)
  async (obj: any) => {
    const res = await AdminService.UpdatePaymentEntry(obj);
    return res.data;
  }
);

export const ActionCompletePayment: any = createAsyncThunk(
  "LoginUserReducer/ActionCompletePayment",
  // naming convention (reducer/action_name)
  async (obj: any) => {
    const res = await AdminService.CompletePayment(obj);
    return res.data;
  }
);
