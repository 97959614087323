export const GetTokenFromLocal = () => {
  if (!window.localStorage.getItem("token")) return;
  return window.localStorage.getItem("token");
};

export const GetToken = () => {
  if (!window.localStorage.getItem("token")) return;
  const strData: any = window.localStorage.getItem("token");
  if (strData) {
    return JSON.parse(strData).accessToken;
  }
  return;
};

export const SetToken = (token: string) => {
  return window.localStorage.setItem("token", token);
};
export const SetUserData = (data: string) => {
  return window.localStorage.setItem("user", data);
};
