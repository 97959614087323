import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePageData } from "visi/provider/PageDataProvider";
import { AgentList, AgentsDetails, NewAgents } from "../process";

type Props = {};

export default function AgentsWrapper({}: Props) {
  // routes
  const navigate = useNavigate();

  // providers
  const { setHeadersLinks, setActiveHeadersLinks, activeHeadersLinks } =
    usePageData();
  // redux

  // states
  const [selectedAgent, setSelectedAgent] = useState("");

  // function

  // useeffects

  useEffect(() => {
    setHeadersLinks([
      { link: "agents", title: "Agents" },
      { link: "newAgents", title: "New Registrations" },
      { link: "agentsProfile", title: "Agent Profile" },
    ]);
    setActiveHeadersLinks("Agents");
    // setActiveHeadersLinks("Agent Profile");
  }, []);

  return (
    <>
      {activeHeadersLinks === "Agents" && (
        <AgentList setSelectedAgent={setSelectedAgent} />
      )}
      {activeHeadersLinks === "New Registrations" && (
        <NewAgents setSelectedAgent={setSelectedAgent} />
      )}
      {activeHeadersLinks === "Agent Profile" && (
        <AgentsDetails selectedAgent={selectedAgent} />
      )}
    </>
  );
}
