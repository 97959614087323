import React, { useEffect } from "react";
import Button from "../ui-components/Button";
import Image from "../ui-components/Image";
import { Link, useLocation } from "react-router-dom";
import { usePageData } from "visi/provider/PageDataProvider";

type Props = {};

export default function MainHeader({}: Props) {
  // urls
  const route = useLocation();

  // providers
  const {
    headersLinks,
    setHeadersLinks,
    setActiveHeadersLinks,
    activeHeadersLinks,
  } = usePageData();
  useEffect(() => {
    if (route.pathname.split("/")[1] !== "agents") {
      setHeadersLinks([]);
    }
  }, [route]);

  return (
    <>
      <div className="flex justify-between py-6 w-full border-b-[0.5px] px-12 border-gray-100">
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-start items-center w-full gap-4">
            {headersLinks.length >= 1 ? (
              headersLinks.map((item: any, index: any) => {
                return (
                  <div
                    onClick={() => setActiveHeadersLinks(item.title)}
                    key={index}
                    className={`${
                      activeHeadersLinks === item.title
                        ? "text-black"
                        : "text-gray-300"
                    } cursor-pointer bg-sky-100  font-[500] text-[16px] capitalize py-2 px-4 rounded-md`}
                  >
                    {item.title}
                  </div>
                );
              })
            ) : (
              <Link
                to={"/"}
                className="bg-sky-100 text-gray-800 font-[600] capitalize py-2 px-4 rounded-md"
              >
                dashboard
              </Link>
            )}
          </div>
          <div>
            <Image image={"avatar.svg"} />
          </div>
        </div>
      </div>
    </>
  );
}
