import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";
import Image from "visi/components/ui-components/Image";
import Input from "visi/components/ui-components/Input";
import Spinner from "visi/components/ui-components/Spinner";
import Table from "visi/components/ui-components/Table";
import { TablePaginationLimit } from "visi/helper";
import { usePageData } from "visi/provider/PageDataProvider";
import {
  ActionsGetAgentsDetails,
  ActionsGetNewAgentsDetails,
} from "visi/store/client/actions";
import { useAdminSelector } from "visi/store/reducers";

type Props = { setSelectedAgent: any };

export function NewAgents({ setSelectedAgent }: Props) {
  // routes
  const navigate = useNavigate();

  // providers
  const { setHeadersLinks, setActiveHeadersLinks, activeHeadersLinks } =
    usePageData();
  // redux
  const dispatch = useDispatch();
  const { newAgentsList, newAgentLoading, newAgentsListCount } =
    useAdminSelector();

  // states
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [value, setValue] = useState<any>({
    startDate: moment().subtract(30, "days"),
    endDate: moment(),
  });
  // function
  const UsersListOfItems = ({ pageNo }: any) => {
    const obj = {
      startDate: moment(value?.startDate).format("MM-DD-YYYY"),
      // endDate: moment(value?.endDate).format("MM-DD-YYYY"),
      endDate: "04-06-2024",
      // endDate: moment(value?.endDate).format("MM-DD-YYYY"),
      pageNo: pageNo,
      limit: TablePaginationLimit,
      // verified: "pending",
    };
    dispatch(ActionsGetNewAgentsDetails(obj));
  };

  const NextClick = (index: any) => {
    console.log(index);
    setCurrentPage(index);
    UsersListOfItems({ pageNo: index });
  };

  const PaginationClick = (page: any) => {
    setCurrentPage(page);
    UsersListOfItems({ pageNo: page });
  };

  const PreviousClick = (index: any) => {
    setCurrentPage(index);
    UsersListOfItems({ pageNo: index });
  };

  const RedirectToDetails = (id: any) => {};

  const SearchItems = (query: any) => {
    if (query.length > 0) {
      console.log("first");
      //   dispatch(ActionsGetUserDetailsSearched(query));
    } else {
      setCurrentPage(1);
      UsersListOfItems({ pageNo: 1 });
    }
  };

  // useeffects
  useEffect(() => {
    setCurrentPage(1);
    UsersListOfItems({ pageNo: 1 });
  }, [value]);

  return (
    <>
      <div className="mt-12 ">
        <Table
          nextClick={() => {
            NextClick(
              newAgentsListCount / TablePaginationLimit > currentPage
                ? currentPage + 1
                : currentPage
            );
          }}
          previousClick={() => {
            PreviousClick(currentPage <= 1 ? 1 : currentPage - 1);
          }}
          paginationClick={PaginationClick}
          totalRecord={newAgentsListCount}
          limit={TablePaginationLimit}
          currentPage={currentPage}
          label={"New Registrations"}
          topMargin={"mt-0"}
          color={""}
          labelClass={"border-b-[1px] border-gray-100"}
          actionButton={
            <>
              <div className="flex flex-row justify-end gap-12">
                <div className="border-[1px] border-gray-100 flex flex-row justify-start rounded-md px-4 mb-2">
                  <Image image={"magnifier.svg"} type={"svg"} />
                  <Input
                    onChange={(e: any) => SearchItems(e.target.value)}
                    inputClass={"active:none border-none"}
                    type={"text"}
                    placeholder={"Search..."}
                  />
                </div>
                <span className="flex justify-end xl:gap-4 sm:xl:gap-2 xl:flex-row sm:flex-col  xl:items-center sm:items-end mb-2">
                  <p className="font-inter text-gray-800 font-semibold text-[18px] flex flex-wrap gap-2 relative">
                    <Datepicker
                      toggleClassName="absolute bg-sky-300 rounded-r-lg text-white right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
                      maxDate={new Date()}
                      startFrom={new Date()}
                      inputClassName={" font-500 w-[290px] h-[32px] opacity-0"}
                      containerClassName="text-black absolute -right-[50px] "
                      readOnly={true}
                      showFooter={true}
                      separator={"to"}
                      primaryColor={"violet"}
                      showShortcuts={false}
                      value={value}
                      onChange={(e: any) => setValue(e.target.value)}
                    />
                    {moment(value?.startDate).format("DD-MMM-YYYY")}
                    <span>to</span>
                    {moment(value?.endDate).format("DD-MMM-YYYY")}
                  </p>
                  <p className="font-inter text-white font-semibold text-[12px] relative">
                    <Image image={"time.svg"} type={"svg"} />
                  </p>
                </span>
              </div>
            </>
          }
          thead={
            <>
              <thead className="bg-white border-b-[1px] border-gray-100">
                <tr className="">
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-800 capitalize "
                  >
                    Agent ID
                  </th>
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-800 capitalize "
                  >
                    Agent Phone
                  </th>
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-800 capitalize "
                  >
                    Agent Name
                  </th>
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-800 capitalize "
                  >
                    Registration
                  </th>
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-800 capitalize "
                  >
                    Documents
                  </th>
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-800 capitalize "
                  >
                    Approval
                  </th>
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-800 capitalize "
                  >
                    Reasons
                  </th>
                </tr>
              </thead>
            </>
          }
          tbody={
            <>
              <tbody className="bg-white">
                {!newAgentLoading ? (
                  newAgentsList ? (
                    newAgentsList?.map((item: any, index: any) => {
                      return (
                        <>
                          <tr
                            onClick={() => {
                              setActiveHeadersLinks("Agent Profile");
                              setSelectedAgent(item);
                            }}
                            key={index}
                            className="cursor-pointer hover:bg-gray-100  border-b-[1px] border-gray-100"
                          >
                            <td className="py-4 px-4 text-gray-400 text-md font-400  whitespace-nowrap text-left">
                              {item?._id ? item?._id : ""}
                            </td>
                            <td className="py-4 px-4 text-gray-400  whitespace-nowrap ">
                              {item?.phoneNo ? item?.phoneNo : ""}
                            </td>
                            <td className="py-4 px-4 text-gray-400  whitespace-nowrap ">
                              {item?.fullName ? item?.fullName : ""}
                            </td>
                            <td className="py-4 px-4 text-gray-400 whitespace-nowrap">
                              {item?.created
                                ? moment(item?.created).format("MMM DD, YYYY")
                                : ""}
                            </td>
                            <td className="py-4 px-4 text-sm font-medium text-gray-400  whitespace-nowrap flex">
                              {item?.created
                                ? moment(item?.created).format("MMM DD, YYYY")
                                : "NA"}
                            </td>
                            <td className={`${item?.isVerified === "pending" ? "text-yellow-800" : item?.isVerified === "approved" ? "text-green-600" : item?.isVerified === "rejected" ? "text-red-800 " : ""} py-4 px-4 text-sm font-semibold whitespace-nowrap capitalize`}>
                              {item?.isVerified ? item?.isVerified : "NA"}
                            </td>
                            <td className="py-4 px-4 text-sm font-medium text-gray-400 whitespace-nowrap capitalize">
                              {item?.isVerified === "approved"
                                ? "OK"
                                : item?.reason !== ""
                                ? item?.reason
                                : "-"}
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <p className="py-4">Data not found</p>
                  )
                ) : (
                  <div className="py-20">
                    <Spinner width={40} height={40} />
                  </div>
                )}
              </tbody>
            </>
          }
        />
      </div>
    </>
  );
}
