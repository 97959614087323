export const AgentListData = {
  statusCode: 1,
  responseData: {
    message: "Details fetched successfully!",
    result: [
      {
        _id: "dummmyId",
        phoneNo: "5555555599",
        status: 1,
        created: "2024-03-06T16:58:45.656Z",
        refferalCode: "DFRE1234",
        shopReffered: "23",
        commission: "233",
      },
    ],
    totalCount: 9,
  },
  requestParams: {},
  time: "2024-03-29T15:41:15.867Z",
};
export const NewAgentListData = {
  statusCode: 1,
  responseData: {
    message: "New Agents list fetched successfully!",
    result: [
      {
        _id: "dummmyId",
        phoneNo: "4444444444",
        agentName: "Dummy Agents",
        created: "2024-03-06T16:58:45.656Z",
        documents: "2024-03-06T16:58:45.656Z",
        status: 1,
        approval: "approved",
        reason: "ok",
      },
    ],
    totalCount: 9,
  },
  requestParams: {},
  time: "2024-03-29T15:41:15.867Z",
};

export const stateJson = [
  { code: "GJ", name: "Gujarat" },
  { code: "UP", name: "Uttar Pradesh" },
];
export const cityJson = [
  { code: "AH", name: "Ahamadabad" },
  { code: "GN", name: "Gandhi Nagar" },
  { code: "AG", name: "Agra" },
  { code: "LK", name: "Lucknow" },
];
