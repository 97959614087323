import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "visi/components/ui-components/Button";
import Image from "visi/components/ui-components/Image";
import Input from "visi/components/ui-components/Input";
import Spinner from "visi/components/ui-components/Spinner";
import { GetTokenFromLocal } from "visi/helper/Sessions";
import { usePageData } from "visi/provider/PageDataProvider";
import { ActionAdminLogin } from "visi/store/client/actions";

type Props = {};

export default function LoginPage({}: Props) {
  // url
  // redux
  const { setCurrentUser } = usePageData();
  const dispatch = useDispatch();

  // state
  const [username, setUsername] = useState("admin@visi.boo");
  const [password, setPassword] = useState("admin@321");
  const [loading, setLoading] = useState<boolean>(false);

  // functions
  const LoginAdmin = () => {
    setLoading(true);
    const obj = {
      username,
      password,
    };
    dispatch(ActionAdminLogin(obj))
      .then((res: any) => {
        setLoading(false);
        if (res?.payload?.responseData?.accessToken) {
          setCurrentUser({
            ...res?.payload?.responseData?.accessToken,
            ...res?.payload?.responseData?.result,
          });
          setLoading(false);
          window.location.reload();

          // <Navigate to={"/"} />;
        } else {
          if (res?.payload?.error?.responseMessage === "Not found") {
            toast("Username and Password not found!");
          } else {
            toast("Something went Wrong! or Email or Password is wrong!");
          }
        }
      })
      .catch((err: any) => {
        setLoading(false);
        toast("Something went Wrong! or Email or Password is wrong!");
      });
  };

  // useEffect(() => {
  //   if (GetTokenFromLocal()) {
  //     // setCurrentUser(GetTokenFromLocal());
  //     console.log(GetTokenFromLocal(), GetTokenFromLocal);
  //     // <Navigate to={"/"} />;
  //   }
  // }, []);

  return (
    <>
      <div className="h-screen flex flex-row relative">
        <div className="w-1/2 h-screen">
          <div className=" flex justify-center items-center flex-col m-auto h-full gap-10">
            <Image image="loginlogo.svg" />
            <div className="w-[400px]">
              <div className="mb-4">
                <Input
                  value={username}
                  inputClass={
                    "border-none rounded-md h-[50px] font-14 w-full text-gray-300 text-medium shadow-[0px_4px_4px_0px_#00000025;]"
                  }
                  onChange={(e: any) => {
                    setUsername(e.target.value);
                  }}
                  type={"text"}
                  placeholder={"Email..."}
                />
              </div>
              <div className="w-[400px]">
                <Input
                  inputClass={
                    "border-none rounded-md h-[50px] font-14 w-full text-gray-300 text-medium shadow-[0px_4px_4px_0px_#00000025;]"
                  }
                  value={password}
                  onChange={(e: any) => {
                    setPassword(e.target.value);
                  }}
                  type={"password"}
                  placeholder={"Password..."}
                />
              </div>
              <div className="mt-10 flex justify-center w-full">
                <Button
                  className={
                    "px-4 py-2 bg-sky-800 text-white text-13 font-regular flex flex-row items-center justify-center gap-3 duration-100"
                  }
                  onClick={() => {
                    LoginAdmin();
                  }}
                >
                  Sign In
                  {loading ? <Spinner height={20} width={20} /> : ""}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="w-1/2 h-screen">
          <img
            className="absolute w-1/2 z-10"
            src="/assets/svg/loginbackground.svg"
            alt=""
          />
          <p className="font-medium text-center pl-28 text-white text-[96px] relative z-20">
            visi.boo
          </p>
        </div>
      </div>
    </>
  );
}
