import axios from "axios";
import { RateUsBaseUrl } from "./Urls";
import { GetToken } from "visi/helper/Sessions";

export const HttpAxios = axios.create({
  baseURL: RateUsBaseUrl,
  headers: {
    Accept: "application/json",
    Authorization: "Basic ZGVtbzpkZW1v",
    accessToken: localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token") || "").accessToken
      : "",
  },
});

export default HttpAxios;
