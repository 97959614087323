import { HttpAxios } from "../../../services/http/HttpAxios";
import {
  AdminLoginUrl,
  ApproveAgentUrl,
  CompletePaymentUrl,
  GetAgentsPaymentListUrl,
  GetDashboardDataUrl,
  GetNewAgentsDetailsUrl,
  RegisteredAgentCardsUrl,
  RegisteredAgentListUrl,
  RegisteredShopsListUrl,
  RegisteredUserListUrl,
  ToGenerateRefferalCodeUrl,
  ToGetAgentDetailsUrl,
  UpdateAgentUrl,
  UpdatePaymentEntryUrl,
} from "../../endpoint/UrlEndPoints";

class LoginUserService {
  // admon logon api
  async AdminLogin(obj: any) {
    return HttpAxios.post(AdminLoginUrl, obj);
  }
  // get dashboard data in cards
  async GetDashboardDetails(obj: any) {
    return HttpAxios.get(
      GetDashboardDataUrl +
        `?startDate=${obj.startDate}&endDate=${obj.endDate}`,
      obj
    );
  }
  // get shoplist  data in cards
  async GetShopDetails(obj: any) {
    return HttpAxios.get(
      RegisteredShopsListUrl +
        `?startDate=${obj.startDate}&endDate=${obj.endDate}&pageNo=${
          obj.pageNo ? obj?.pageNo : 1
        }&limit=${obj.limit ? obj.limit : 10}`,
      obj
    );
  }
  // get user list data in cards
  async GetUserDetails(obj: any) {
    return HttpAxios.get(
      RegisteredUserListUrl +
        `?startDate=${obj.startDate}&endDate=${
          obj.endDate
        }&userType=1&&pageNo=${obj.pageNo ? obj?.pageNo : 1}&limit=${
          obj.limit ? obj.limit : 10
        }`,
      obj
    );
  }
  // get agents list data in cards
  async GetAgentsDetails(obj: any) {
    return HttpAxios.get(
      RegisteredAgentListUrl +
        `?startDate=${obj.startDate}&endDate=${
          obj.endDate
        }&userType=1&&pageNo=${obj.pageNo ? obj?.pageNo : 1}&limit=${
          obj.limit ? obj.limit : 10
        }`,
      obj
    );
  }
  // get new agents list data in cards
  async GetNewAgentsDetails(obj: any) {
    return HttpAxios.get(
      GetNewAgentsDetailsUrl +
        `?startDate=${obj.startDate}&endDate=${
          obj.endDate
        }&userType=1&&pageNo=${obj.pageNo ? obj?.pageNo : 1}&limit=${
          obj.limit ? obj.limit : 10
        }
        `,
      // &verified=${obj.verified}
      obj
    );
  }
  // get user list searched data in cards
  async GetUserDetailsSearched(obj: any) {
    return HttpAxios.get(RegisteredUserListUrl + `?search=${obj}`, obj);
  }
  // get shop list searched data in cards
  async GetShopDetailsSearched(obj: any) {
    return HttpAxios.get(RegisteredShopsListUrl + `?search=${obj}`, obj);
  }
  // get agents cards list in agent dashboard
  async GetAgentsCards(obj: any) {
    return HttpAxios.get(RegisteredAgentCardsUrl);
  }
  // get user list searched data in cards
  async GetAgentsSearchedDetails(obj: any) {
    return HttpAxios.get(RegisteredAgentListUrl + `?search=${obj}`, obj);
  }
  // get user list searched data in cards
  async ToGetAgentDetails(obj: any) {
    return HttpAxios.get(ToGetAgentDetailsUrl + `/${obj}`);
  }
  // togenerate refferal unique code
  async ToGenerateRefferalCode(obj: any) {
    return HttpAxios.get(ToGenerateRefferalCodeUrl + `/${obj}`);
  }
  // approve agent profile
  async ApproveAgent(obj: any) {
    return HttpAxios.post(ApproveAgentUrl, obj);
  }
  // update agent profile
  async UpdateAgent(obj: any) {
    return HttpAxios.put(UpdateAgentUrl, obj);
  }
  // get payment list of agents
  async GetAgentsPaymentList(obj: any) {
    return HttpAxios.post(GetAgentsPaymentListUrl, obj);
  }
  // update agent profile
  async UpdatePaymentEntry(obj: any) {
    return HttpAxios.put(UpdatePaymentEntryUrl, obj);
  }
  // complete payment from admin
  async CompletePayment(obj: any) {
    return HttpAxios.post(CompletePaymentUrl, obj);
  }
}

export default new LoginUserService();
