import React, { useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import App from "../App";
import PrivateRoutes from "./PrivateRoutes";
import { GetTokenFromLocal } from "visi/helper/Sessions";
import LoginPage from "app/pages/auth/LoginPage";
import { usePageData } from "visi/provider/PageDataProvider";
import DashboardWrapper from "app/pages/dashboards/DashboardWrapper";

type Props = {};

const { Public_Url } = process.env;

export default function AppRoutes({}: Props) {
  // url
  // providers
  const { currentUser, setCurrentUser } = usePageData();

  useEffect(() => {
    const token = GetTokenFromLocal();
    setCurrentUser(token);
  }, [setCurrentUser, currentUser]);

  return (
    <BrowserRouter basename={Public_Url}>
      <Routes>
        <Route element={<App />}>
          {currentUser ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
            </>
          ) : (
            <>
              <Route path="*" element={<Navigate to={"/login"} />} />
              <Route path="/login" element={<LoginPage />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
