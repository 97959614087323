import React from "react";

type Props = {
  label: any;
  id: any;
  onChange: any;
  labelClass?: any;
  className?: any;
  children: any;
  disabled?: any;
};

export default function DropDown({
  onChange,
  label,
  id,
  labelClass,
  children,
  disabled,
  className,
}: Props) {
  return (
    <div className="w-full">
      <label
        htmlFor={id}
        className={` ${labelClass} block text-normal text-gray-700 font-semibold capitalize`}
      >
        {label}
      </label>
      <select
        disabled={disabled}
        onChange={onChange}
        id={id}
        className={`${className} ${
          label ? "mt-2" : ""
        } border border-gray-100 hover:border-red text-gray-900 text-sm rounded-md block w-full p-2.5 `}
      >
        {children}
      </select>
    </div>
  );
}
