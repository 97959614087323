import LoginPage from "app/pages/auth/LoginPage";
import AgentsWrapper from "app/pages/dashboards/AgentsWrapper";
import CustomersDashboard from "app/pages/dashboards/CustomersDashboard";
import DashboardWrapper from "app/pages/dashboards/DashboardWrapper";
import PaymentsWrapper from "app/pages/dashboards/PaymentsWrapper";
import SettingsWrapper from "app/pages/dashboards/SettingsWrapper";
import ShopDashboard from "app/pages/dashboards/ShopDashboard";
import { AgentsDetails } from "app/pages/process";
import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { GetTokenFromLocal } from "visi/helper/Sessions";
import { MasterLayout } from "visi/layout/MasterLayout";
import { usePageData } from "visi/provider/PageDataProvider";

type Props = {};

export default function PrivateRoutes({}: Props) {
  // providers
  return (
    <>
      <Routes>
        <Route element={<MasterLayout />}>
          <Route path={"*"} index element={<Navigate to={"/"} />} />
          <Route path="/" element={<DashboardWrapper />} />
          <Route path="/shops" element={<ShopDashboard />} />
          <Route path="/customers" element={<CustomersDashboard />} />
          <Route path="/settings" element={<SettingsWrapper />} />
          <Route path="/payments" element={<PaymentsWrapper />} />
          <Route path="/agents" element={<AgentsWrapper />} />
          {/* <Route path="/agents/:id" element={<AgentsDetails />} /> */}
        </Route>
      </Routes>
    </>
  );
}
