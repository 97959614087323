import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AgentsApprovalsCard from "visi/components/cards/AgentsApprovalsCard";
import AgentsDetailedCard from "visi/components/cards/AgentsDetailedCard";
import AgentsPaymentsCard from "visi/components/cards/AgentsPaymentsCard";
import Image from "visi/components/ui-components/Image";
import { usePageData } from "visi/provider/PageDataProvider";
import { ActionToGetAgentDetails } from "visi/store/client/actions";
import { useAdminSelector } from "visi/store/reducers";

type Props = { selectedAgent: any };

export function AgentsDetails({ selectedAgent }: Props) {
  // urls
  // providers
  // redux
  const dispatch = useDispatch();
  const { agentDetails, agentDetailsLoading } = useAdminSelector();
  // states
  const [tabSelected, setTabSelected] = useState("overview");
  const [startEdit, setStartEdit] = useState(false);
  // functions
  const EditAgentDetails = () => {
    setStartEdit(true);
  };
  // useeffects
  useEffect(() => {
    dispatch(ActionToGetAgentDetails(selectedAgent._id));
  }, [selectedAgent]);

  return (
    <>
      <>
        <div className="flex justify-start flex-row gap-4 mt-[40px]">
          <div>
            {
              <img
                width={250}
                height={100}
                src={agentDetails?.profilePhoto}
                loading="lazy"
                alt={"profile pic"}
              />
            }
          </div>
          {/* <Image image={"dummyProflePic.png"} type={"png"} /> */}
          <div className="flex justify-center flex-col">
            <div className="flex justify-start flex-row gap-3">
              <p className="text-[18px] font-semibold text-gray-900">
                {agentDetails?.fullName ? agentDetails?.fullName : "NA"}
              </p>
              {agentDetails?.isVerified && (
                <Image image={"general.svg"} type={"svg"} />
              )}
            </div>
            <div className="flex justify-center flex-row mt-2 gap-3">
              <div className="flex justify-center flex-row gap-1">
                <Image image={"profile-circle.svg"} type={"svg"} />
                <p className="text-gray-400 font-semibold text-[15px]">Agent</p>
              </div>
              <div className="flex justify-center flex-row gap-1">
                <Image image={"sms.svg"} type={"svg"} />
                <p className="text-gray-400 font-semibold text-[15px]">
                  {agentDetails?.phoneNo ? agentDetails?.phoneNo : "NA"}
                </p>
              </div>
              <div className="flex justify-center flex-row gap-1">
                <Image image={"sms.svg"} type={"svg"} />
                <p className="text-gray-400 font-semibold text-[15px]">
                  {agentDetails?.upiId ? agentDetails?.upiId : "NA"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10">
          <div className="flex justify-start gap-[40px] flex-row">
            <p
              onClick={() => {
                setTabSelected("overview");
              }}
              className={`${
                tabSelected === "overview"
                  ? "text-sky-800 border-b-2 border-sky-800"
                  : "text-gray-400"
              } font-semibold cursor-pointer text-[15px] pb-4 `}
            >
              Overview
            </p>
            <p
              onClick={() => {
                setTabSelected("payments");
              }}
              className={`${
                tabSelected === "payments"
                  ? "text-sky-800 border-b-2 border-sky-800"
                  : "text-gray-400"
              } font-semibold cursor-pointer text-[15px] pb-4 `}
            >
              Payments
            </p>
            <p
              onClick={() => {
                setTabSelected("approvals");
              }}
              className={`${
                tabSelected === "approvals"
                  ? "text-sky-800 border-b-2 border-sky-800"
                  : "text-gray-400"
              } font-semibold cursor-pointer text-[15px] pb-4 text-gray-400`}
            >
              Approvals
            </p>
          </div>
        </div>
        <div>
          {tabSelected === "overview" && (
            <AgentsDetailedCard
              actionButton={(type: any) => setStartEdit(type)}
              startEdit={startEdit}
              details={agentDetails}
              title={"Profile Details"}
            />
          )}
          {tabSelected === "payments" && (
            <AgentsPaymentsCard
              title={"Profile Details"}
              details={agentDetails}
            />
          )}
          {tabSelected === "approvals" && (
            <AgentsApprovalsCard
              details={agentDetails}
              title={"Profile Details"}
            />
          )}
        </div>
      </>
    </>
  );
}
