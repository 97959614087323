import { Suspense } from "react";
import { Outlet } from "react-router-dom";

const SuspenceComponent = () => {
  return (
    <>
      <p className="font-semibold text-black">Loading Application...</p>
    </>
  );
};

function App() {
  return (
    <Suspense fallback={<SuspenceComponent />}>
      <Outlet />
    </Suspense>
  );
}

export default App;
