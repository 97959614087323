import React from "react";

type Props = {
  onClick: any;
  className?: any;
  text?: any;
  type?: any;
  children?: any;
};

export default function Button({
  onClick,
  className,
  text,
  type,
  children,
}: Props) {
  return (
    <>
      <button
        onClick={() => {
          onClick();
        }}
        type={type}
        className={`${className} rounded-md font-inter`}
      >
        {text}
        {children}
      </button>
    </>
  );
}

Button.defaultProps = {
  className: "px-4 py-2",
  type: "button",
};
