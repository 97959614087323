import { Outlet } from "react-router-dom";
import { Footer } from "visi/components/footer/Footer";
import { AsideDefault } from "visi/components/aside/AsideDefault";
import MainHeader from "visi/components/header/MainHeader";

const MasterLayout = () => {
  return (
    <>
      <div className="flex flex-row font-inter">
        {/* begin::Page */}
        <div className="px-4 justify-center min-h-screen bg-violet-200 fixed">
          <AsideDefault />
        </div>
        <div className="w-full ml-[122px]">
          {/* begin::Wrapper */}
          <div className="w-full">
            <MainHeader />
          </div>
          {/* begin::Content */}
          <div className="col-12 px-12 min-h-screen">
            <Outlet />
          </div>
          {/* end::Content */}
          <div className="px-12">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export { MasterLayout };
