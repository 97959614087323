import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DashboardDataCards from "visi/components/cards/DashboardDataCards";
import DashboardDataCardsLarge from "visi/components/cards/DashboardDataCardsLarge";
import { ActionsGetDashboardDetails } from "visi/store/client/actions";
import { useAdminSelector } from "visi/store/reducers";
import moment from "moment";

type Props = {};

export default function DashboardWrapper({}: Props) {
  // redux
  const dispatch = useDispatch();
  const { dashboardData } = useAdminSelector();
  // states
  const [value, setValue] = useState<any>({
    startDate: moment().subtract(30, "days"),
    endDate: moment(),
  });
  // functions

  const handleValueChange = (newValue: any) => {
    setValue(newValue);
  };

  // useeffects
  useEffect(() => {
    const obj = {
      startDate: moment(value?.startDate).format("MM-DD-YYYY"),
      endDate: moment(value?.endDate).format("MM-DD-YYYY"),
    };
    dispatch(ActionsGetDashboardDetails(obj));
  }, [value]);

  return (
    <>
      <div className="py-12 grid xl:grid-cols-4 sm:grid-cols-2 gap-8">
        <DashboardDataCards
          className={"bg-pink-600 bg-pinkpattern bg-cover bg-no-repeat"}
          text={"Active Shops"}
          numbers={dashboardData?.totalStore ? dashboardData?.totalStore : 0}
          backgroundimage={"cardpattern.svg"}
        />
        <DashboardDataCards
          backgroundimage={"greenpattern.svg "}
          className={"bg-green-600 bg-greenpattern bg-cover bg-no-repeat"}
          text={"Active Customers"}
          numbers={
            dashboardData?.activeUserCount ? dashboardData?.activeUserCount : 0
          }
        />
        <DashboardDataCardsLarge
          className={"bg-sky-800 xl:col-span-2 sm:col-span-1"}
          text={"Payment Received"}
          numbers={
            dashboardData?.totalPayment ? dashboardData?.totalPayment : 0
          }
          value={value}
          handleValueChange={handleValueChange}
        />
      </div>
    </>
  );
}
