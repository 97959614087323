import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";
import DashboardDataCards from "visi/components/cards/DashboardDataCards";
import Image from "visi/components/ui-components/Image";
import Input from "visi/components/ui-components/Input";
import Spinner from "visi/components/ui-components/Spinner";
import Table from "visi/components/ui-components/Table";
import {
  ActionsGetDashboardDetails,
  ActionsGetShopDetails,
  ActionsGetUserDetails,
  ActionsGetUserDetailsSearched,
} from "visi/store/client/actions";
import { useAdminSelector } from "visi/store/reducers";
// import Spinner from "visi/components/ui-components/spinner";

type Props = {};

export default function CustomersDashboard({}: Props) {
  // url
  // redux
  const dispatch = useDispatch();
  const { dashboardData, usersData, usersLoading, usersDataCount } =
    useAdminSelector();
  // states
  const [value, setValue] = useState<any>({
    startDate: moment().subtract(30, "days"),
    endDate: moment(),
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);

  // functions

  const handleValueChange = (newValue: any) => {
    setValue(newValue);
  };
  const UsersListOfItems = ({ pageNo }: any) => {
    const obj = {
      startDate: moment(value?.startDate).format("MM-DD-YYYY"),
      endDate: moment(value?.endDate).format("MM-DD-YYYY"),
      pageNo: pageNo,
      limit: limit,
    };
    dispatch(ActionsGetUserDetails(obj));
  };

  const NextClick = (index: any) => {
    setCurrentPage(index);
    UsersListOfItems({ pageNo: index });
  };

  const PreviousClick = (index: any) => {
    setCurrentPage(index);
    UsersListOfItems({ pageNo: index });
  };

  const PaginationClick = (page: any) => {
    setCurrentPage(page);
    UsersListOfItems({ pageNo: page });
  };

  const SearchItems = (query: any) => {
    if (query.length > 0) {
      dispatch(ActionsGetUserDetailsSearched(query));
    } else {
      setCurrentPage(1);
      UsersListOfItems({ pageNo: 1 });
    }
  };
  // useeffects
  useEffect(() => {
    setCurrentPage(1);
    UsersListOfItems({ pageNo: 1 });
  }, [value]);

  return (
    <>
      <div className="py-12 grid grid-cols-4 gap-8">
        <DashboardDataCards
          className={"bg-pink-600 bg-pinkpattern bg-cover bg-no-repeat"}
          text={"Active Customers"}
          numbers={
            dashboardData?.activeUserCount ? dashboardData?.activeUserCount : 0
          }
          backgroundimage={"cardpattern.svg"}
        />
      </div>
      <div className="mt-12 ">
        <Table
          nextClick={() => {
            NextClick(
              usersDataCount / limit > currentPage
                ? currentPage + 1
                : currentPage
            );
          }}
          previousClick={() => {
            PreviousClick(currentPage <= 1 ? 1 : currentPage - 1);
          }}
          paginationClick={PaginationClick}
          totalRecord={usersDataCount}
          limit={limit}
          currentPage={currentPage}
          label={"Registered Shops"}
          topMargin={"mt-0"}
          color={""}
          labelClass={"border-b-[1px] border-gray-100"}
          actionButton={
            <>
              <div className="flex flex-row justify-end gap-12">
                <div className="border-[1px] border-gray-100 flex flex-row justify-start rounded-md px-4 mb-2">
                  <Image image={"magnifier.svg"} type={"svg"} />
                  <Input
                    onChange={(e: any) => SearchItems(e.target.value)}
                    inputClass={"active:none border-none"}
                    type={"text"}
                    placeholder={"Search..."}
                  />
                </div>
                <span className="flex justify-end xl:gap-4 sm:xl:gap-2 xl:flex-row sm:flex-col  xl:items-center sm:items-end mb-2">
                  <p className="font-inter text-gray-800 font-semibold text-[18px] flex flex-wrap gap-2 relative">
                    <Datepicker
                      toggleClassName="absolute bg-sky-300 rounded-r-lg text-white right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
                      maxDate={new Date()}
                      startFrom={new Date()}
                      inputClassName={" font-500 w-[290px] h-[32px] opacity-0"}
                      containerClassName="text-black absolute -right-[50px] "
                      readOnly={true}
                      showFooter={true}
                      separator={"to"}
                      primaryColor={"violet"}
                      showShortcuts={false}
                      value={value}
                      onChange={handleValueChange}
                    />
                    {moment(value?.startDate).format("DD-MMM-YYYY")}
                    <span>to</span>
                    {moment(value?.endDate).format("DD-MMM-YYYY")}
                  </p>
                  <p className="font-inter text-white font-semibold text-[12px] relative">
                    <Image image={"time.svg"} type={"svg"} />
                  </p>
                </span>
              </div>
            </>
          }
          thead={
            <>
              <thead className="bg-white border-b-[1px] border-gray-100">
                <tr className="">
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-800 capitalize "
                  >
                    Customer ID
                  </th>
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-800 capitalize "
                  >
                    Customer Number
                  </th>
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-800 capitalize "
                  >
                    Registration
                  </th>
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-800 capitalize "
                  >
                    Last Rated
                  </th>
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-800 capitalize "
                  >
                    Ratings
                  </th>
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-800 capitalize "
                  >
                    Shop Rated
                  </th>
                </tr>
              </thead>
            </>
          }
          tbody={
            <>
              <tbody className="bg-white">
                {!usersLoading ? (
                  usersData ? (
                    usersData
                      ?.filter((user: any) => (user?.phoneNo ? user : ""))
                      ?.map((item: any, index: any) => {
                        return (
                          <tr
                            key={index}
                            className="hover:bg-gray-100  border-b-[1px] border-gray-100"
                          >
                            <td className="py-4 px-4 text-gray-400 text-md font-400  whitespace-nowrap text-left">
                              {item._id ? item._id : ""}
                            </td>
                            <td className="py-4 px-4 text-gray-400  whitespace-nowrap ">
                              {item.phoneNo ? item.phoneNo : ""}
                            </td>
                            <td className="py-4 px-4 text-gray-400 whitespace-nowrap">
                              {item.created
                                ? moment(item.created).format("MMM DD, YYYY")
                                : ""}
                            </td>
                            <td className="py-4 px-4 text-sm font-medium text-gray-400  whitespace-nowrap flex">
                              {item.lastRated
                                ? moment(item.lastRated).format("MMM DD, YYYY")
                                : "NA"}
                            </td>
                            <td className="py-4 px-4 text-sm font-semibold text-violet-800  whitespace-nowrap ">
                              {item.ratings ? item.ratings : "0"}
                            </td>
                            <td className="py-4 px-4 text-sm font-medium text-gray-400 whitespace-nowrap ">
                              {item.shopCount ? item.shopCount : 0}
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <p className="py-4">Data not found</p>
                  )
                ) : (
                  <div className="py-20">
                    <Spinner width={40} height={40} />
                  </div>
                )}
              </tbody>
            </>
          }
        />
        {/* //   ) : (
        //     <div className=" m-auto h-auto p-4">
        //       <Spinner size="w-20 h-20" />
        //     </div>
        //   )
        // ) : (
        //   <p className="p-4">Data Not Found</p>
        // )} */}
      </div>
    </>
  );
}
