import React from "react";

type Props = { image: string; type: string; className?: any };

export default function Image({ image, className, type }: Props) {
  return (
    <>
      <div className={`${className} flex justify-center`}>
        {type === "svg" && (
          <img src={`/assets/svg/${image}`} alt={image || "alt"} />
        )}
        {type === "png" && (
          <img src={`/assets/png/${image}`} alt={image || "alt"} />
        )}
      </div>
    </>
  );
}

Image.defaultProps = {
  type: "svg",
};
