const Footer = () => {
  return (
    <div className={"footer py-4 d-flex flex-lg-column"} id="">
      <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
        <div className="text-primary px-4">
          <span className=" fw-bold me-1">
            {new Date().getFullYear()}&copy;
          </span>
          Visiboo
        </div>
      </div>
    </div>
  );
};

export { Footer };
