import React, { useState } from "react";
import Image from "../ui-components/Image";
import moment from "moment";
import Datepicker from "react-tailwindcss-datepicker";

type Props = {
  className: string;
  text: any;
  numbers: number;
  endDate?: string;
  startDate?: string;
  endDateChange?: any;
  startDateChange?: any;
  value?: any;
  setvalue?: any;
  handleValueChange?: any;
};

export default function DashboardDataCardsLarge({
  className,
  text,
  numbers,
  startDate,
  endDate,
  value,
  handleValueChange,
}: Props) {
  // redux
  // functions

  return (
    <>
      <div className={`${className} h-[220px] relative rounded-lg w-full p-8`}>
        <p className="font-inter text-gray-200 font-semibold text-[18px]">
          {text}
        </p>
        <p className="font-inter text-white font-semibold xl:text-[38px] sm:text-[24px]">
          Rs {numbers}
        </p>
        <div className="absolute bottom-6 right-8 text-center z-20 ">
          <span className="flex justify-end xl:gap-4 sm:xl:gap-2 xl:flex-row sm:flex-col  xl:items-center sm:items-end mt-4">
            <p className="font-inter text-white font-semibold text-[18px] flex flex-wrap gap-2 relative">
              {moment(value?.startDate).format("DD-MMM-YYYY")}
              <span className="">to</span>
              {moment(value?.endDate).format("DD-MMM-YYYY")}
              <Datepicker
                toggleClassName="absolute text-sky-800 right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
                maxDate={new Date()}
                startFrom={new Date()}
                inputClassName={" font-500 w-[290px] h-[32px] opacity-0"}
                containerClassName="text-black absolute -right-[50px] "
                readOnly={true}
                showFooter={true}
                separator={"to"}
                primaryColor={"violet"}
                showShortcuts={false}
                value={value}
                onChange={handleValueChange}
              />
            </p>
            <p className="font-inter text-white font-semibold text-[12px] relative">
              <Image image={"calendar.svg"} type={"svg"} />
            </p>
          </span>
        </div>
      </div>
    </>
  );
}
