import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TablePaginationLimit } from "visi/helper";
import { usePageData } from "visi/provider/PageDataProvider";
import {
  ActionCompletePayment,
  ActionsGetAgentsCards,
  ActionsGetAgentsDetails,
  ActionsGetAgentsPaymentList,
  ActionsGetAgentsSearchedDetails,
  ActionsUpdatePaymentEntry,
} from "visi/store/client/actions";
import { useAdminSelector } from "visi/store/reducers";
import Table from "../ui-components/Table";
import Spinner from "../ui-components/Spinner";
import Image from "../ui-components/Image";
import Button from "../ui-components/Button";
import Input from "../ui-components/Input";
import { toast } from "react-toastify";

type Props = { title: any; details: any };

export default function AgentsPaymentsCard({ details, title }: Props) {
  // console.log(details,'detailsdetailsdetails')
  // routes
  const navigate = useNavigate();

  // providers
  const { setActiveHeadersLinks } = usePageData();
  // redux
  const dispatch = useDispatch();
  const {
    agentPaymentList,
    agentPaymentListLoading,
    agentLoading,
    agentsListCount,
  } = useAdminSelector();
  // states
  const [proof, setProof] = useState<string>("");
  const [paymentId, setPaymentId] = useState<any>("");
  const [totalSelected, setTotalSelected] = useState<number>(0);
  const [selection, setSelection] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [value, setValue] = useState<any>({
    startDate: moment().subtract(30, "days"),
    endDate: moment(),
  });
  // function
  const AgentPaymentListOfItems = async ({ pageNo }: any) => {
    const obj = {
      pageNo: pageNo,
      limit: 10,
      agentId: details._id,
    };
    await dispatch(ActionsGetAgentsPaymentList(obj));
  };

  const NextClick = (index: any) => {
    console.log(index);
    setCurrentPage(index);
    AgentPaymentListOfItems({ pageNo: index });
  };

  const PaginationClick = (page: any) => {
    setCurrentPage(page);
    AgentPaymentListOfItems({ pageNo: page });
  };

  const PreviousClick = (index: any) => {
    setCurrentPage(index);
    AgentPaymentListOfItems({ pageNo: index });
  };

  const SearchItems = async (query: any) => {
    if (query.length > 0) {
      console.log("first");
      await dispatch(ActionsGetAgentsSearchedDetails(query));
    } else {
      setCurrentPage(1);
      AgentPaymentListOfItems({ pageNo: 1 });
    }
  };

  const UpdateTheStatus = async (item: any, status: string) => {
    if (
      item.agentPaymentStatus === "pending" ||
      item.agentPaymentStatus === "due"
    ) {
      if (window.confirm(`Are you want to ${status} this entry!!`)) {
        await dispatch(
          ActionsUpdatePaymentEntry({ status, paymentId: item._id })
        );
        AgentPaymentListOfItems({ pageNo: 1 });
      }
    } else {
      toast("status is not correct!");
    }
  };

  const CheckboxAction = async (e: any, params: any) => {
    const index = selection.indexOf(params._id);
    console.log(selection, "cursor-pointer");
    if (e.target.checked) {
      setTotalSelected(totalSelected + params.getAmount);
      setSelection([...selection, params._id]);
    } else if (e.target.checked === false) {
      if (index > -1) {
        selection.splice(index, 1);
        setSelection([...selection]);
      }
      setTotalSelected(totalSelected - params.getAmount);
    }
  };

  const SubmitData = async () => {
    console.log("proofproof", paymentId === "", typeof paymentId, paymentId);
    if (paymentId === " ") return toast("Please add Payment ID.");
    if (selection.length <= 0) return toast("Please select the row.");
    if (proof === "") return toast("Please add any proof.");

    const formData = new FormData();
    formData.append("agentId", details._id);
    formData.append("paymentsId", selection.toString());
    formData.append("transactionNo", paymentId);
    formData.append("amount", totalSelected.toString());
    formData.append("image", proof);
    await dispatch(ActionCompletePayment(formData));
    AgentPaymentListOfItems({ pageNo: 1 });
    setSelection([]);
    setTotalSelected(0);
    setProof("");
    setPaymentId("");
  };

  // useeffects
  useEffect(() => {
    setCurrentPage(1);
    setSelection([]);
    setTotalSelected(0);
    AgentPaymentListOfItems({ pageNo: 1 });
  }, [value]);
  return (
    <>
      <div className="mt-12 ">
        <Table
          nextClick={() => {
            NextClick(
              agentsListCount / TablePaginationLimit > currentPage
                ? currentPage + 1
                : currentPage
            );
          }}
          previousClick={() => {
            PreviousClick(currentPage <= 1 ? 1 : currentPage - 1);
          }}
          paginationClick={PaginationClick}
          totalRecord={agentsListCount}
          limit={TablePaginationLimit}
          currentPage={currentPage}
          label={
            <div className="flex flex-row justify-between w-full items-center">
              <div className="flex justify-start flex-col mb-3">
                <p className="text-gray-800 font-semibold">Recent Referrals</p>
                <p className="text-gray-400 text-[14px] font-semibold">
                  Over 500 Orders
                </p>
              </div>
              <div className="flex justify-end gap-4 items-center">
                <Image className={"w-12"} type={"svg"} image={"category.svg"} />
                {selection.length >= 1 && (
                  <>
                    <p className="text-gray-800 whitespace-nowrap">
                      ₹ {totalSelected}
                    </p>
                    <Input
                      value={paymentId}
                      inputClass={"py-2"}
                      onChange={(e: any) => {
                        setPaymentId(e.target.value);
                      }}
                      placeholder={"add transaction No."}
                    ></Input>
                    <div>
                      <Button
                        onClick={() => {}}
                        className={
                          "cursor-pointer bg-sky-800 whitespace-nowrap text-white text-[12px] py-1 px-2 relative "
                        }
                      >
                        <input
                          onChange={(e: any) => setProof(e.target.files[0])}
                          type="file"
                          className="-py-1 -px-2 absolute w-full -top-1 -left-1 opacity-0"
                        />
                        Upload Screenshot
                      </Button>
                    </div>
                    <Button
                      onClick={() => {
                        SubmitData();
                      }}
                      className={
                        "cursor-pointer bg-violet-500 text-white text-[12px] py-1 px-4"
                      }
                    >
                      Submit
                    </Button>
                  </>
                )}
              </div>
            </div>
          }
          topMargin={"mt-0"}
          color={""}
          labelClass={"border-b-[1px] border-gray-100"}
          // actionButton={<></>}
          thead={
            <>
              <thead className="bg-white border-b-[1px] border-gray-100">
                <tr className="">
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-400 capitalize "
                  >
                    Order ID
                  </th>
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-400 capitalize "
                  >
                    Shop ID
                  </th>
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-400 capitalize "
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-400 capitalize "
                  >
                    Shop Number
                  </th>
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-400 capitalize "
                  >
                    <p>Total </p>
                    {/* <p className="text-gray-800">₹ {totalSelected}</p> */}
                  </th>
                  <th
                    scope="col"
                    className="w-96 py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-400 capitalize "
                  >
                    <span>Status </span>
                    {/* <div className="flex justify-start gap-2">
                      <Button
                        onClick={() => {}}
                        className={
                          "bg-sky-800 text-white text-[12px] py-1 px-2"
                        }
                      >
                        Add Tr. No.
                      </Button>
                    </div> */}
                  </th>
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-800 capitalize "
                  >
                    Action
                  </th>
                </tr>
              </thead>
            </>
          }
          tbody={
            <>
              <tbody className="bg-white">
                {!agentPaymentListLoading ? (
                  agentPaymentList ? (
                    agentPaymentList?.map((item: any, index: any) => {
                      return (
                        <>
                          <tr
                            onClick={() => {
                              setActiveHeadersLinks("Agent Profile");
                            }}
                            key={index}
                            className="cursor-pointer hover:bg-gray-100  border-b-[1px] border-gray-100"
                          >
                            <td className="py-4 px-4 text-gray-800 font-semibold text-md font-800  whitespace-nowrap text-left">
                              <div className="flex items-center mb-4">
                                {item?.agentPaymentStatus === "due" && (
                                  <input
                                    onChange={(e) => CheckboxAction(e, item)}
                                    id={item._id}
                                    type="checkbox"
                                    value=""
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                )}
                                <label
                                  htmlFor={item._id}
                                  className="ms-2 text-sm text-gray-900 dark:text-gray-300"
                                >
                                  {item._id ? item._id : ""}
                                </label>
                              </div>
                            </td>
                            <td className="py-4 px-4 text-gray-800 font-semibold  whitespace-nowrap ">
                              {item?.store[0]?.name
                                ? item?.store[0]?.name
                                : "NA"}
                              <p className="text-gray-400 text-[14px] font-normal">
                                {item?.store[0]?._id
                                  ? item?.store[0]?._id
                                  : "NA"}
                              </p>
                            </td>
                            <td className="py-4 px-4 text-gray-800 font-semibold whitespace-nowrap">
                              {item.created
                                ? moment(item.created).format("MMM DD, YYYY")
                                : ""}
                              <p className="text-gray-400 text-[14px] font-normal">
                                {item.created
                                  ? moment(item.created).format("MM:HH")
                                  : ""}
                              </p>
                            </td>
                            <td className="py-4 px-4 text-sm text-gray-800 font-semibold  whitespace-nowrap">
                              <p>
                                {item?.store[0]?.phone
                                  ? item?.store[0]?.phone
                                  : "NA"}
                              </p>
                              <p className="text-gray-400 text-[14px] font-normal">
                                {item?.store[0]?.address?.zip
                                  ? item?.store[0]?.address?.zip
                                  : "NA"}
                              </p>
                            </td>
                            <td className="py-4 px-4 text-sm font-semibold text-gray-800  whitespace-nowrap ">
                              ₹{item?.getAmount ? item?.getAmount : "NA"}
                              <p className="text-gray-400 font-normal text-[14px]">
                                ₹{item?.amount ? item?.amount : "NA"} plan
                              </p>
                            </td>
                            <td className="py-4 px-4 text-sm text-gray-800 font-semibold whitespace-nowrap ">
                              {item?.agentPaymentStatus === "due" && (
                                <p className="bg-sky-100  text-sky-800 px-2 rounded-md w-fit text-[12px]">
                                  Due
                                </p>
                              )}
                              {item?.agentPaymentStatus === "disputed" && (
                                <p className="bg-violet-100  text-violet-800 px-2 rounded-md w-fit text-[12px]">
                                  Disputed
                                </p>
                              )}
                              {item?.agentPaymentStatus === "rejected" && (
                                <p className="bg-red-100  text-red-800 px-2 rounded-md w-fit text-[12px]">
                                  Rejected
                                </p>
                              )}
                              {item?.agentPaymentStatus === "pending" && (
                                <p className="bg-yellow-100  text-yellow-800 px-2 rounded-md w-fit text-[12px]">
                                  Pending
                                </p>
                              )}
                              {item?.agentPaymentStatus === "paid" && (
                                <p className="bg-green-100  text-green-600 px-2 rounded-md w-fit text-[12px]">
                                  Paid
                                </p>
                              )}

                              <p className="text-gray-400 text-[14px] font-normal">
                                {item?.transactionNo
                                  ? item?.transactionNo
                                  : "NA"}
                              </p>
                            </td>
                            <td className="py-4 px-4 text-sm text-gray-800 font-semibold whitespace-nowrap ">
                              <div className="flex justify-start flex-row gap-2">
                                <span
                                  onClick={() => {
                                    UpdateTheStatus(item, "disputed");
                                  }}
                                >
                                  <Image
                                    type={"svg"}
                                    image={"actionsettings.svg"}
                                  ></Image>
                                </span>
                                <span
                                  onClick={() => {
                                    UpdateTheStatus(item, "rejected");
                                  }}
                                >
                                  <Image
                                    type={"svg"}
                                    image={"editbutton.svg"}
                                  ></Image>
                                </span>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <p className="py-4">Data not found</p>
                  )
                ) : (
                  <div className="py-20">
                    <Spinner width={40} height={40} />
                  </div>
                )}
              </tbody>
            </>
          }
        />
      </div>
    </>
  );
}
