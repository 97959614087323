import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";
import DashboardDataCards from "visi/components/cards/DashboardDataCards";
import Image from "visi/components/ui-components/Image";
import Input from "visi/components/ui-components/Input";
import Spinner from "visi/components/ui-components/Spinner";
import Table from "visi/components/ui-components/Table";
import { TablePaginationLimit } from "visi/helper";
import { usePageData } from "visi/provider/PageDataProvider";
import {
  ActionsGetAgentsCards,
  ActionsGetAgentsDetails,
  ActionsGetAgentsSearchedDetails,
  ActionsGetUserDetailsSearched,
} from "visi/store/client/actions";
import { useAdminSelector } from "visi/store/reducers";

type Props = { setSelectedAgent: any };

export function AgentList({ setSelectedAgent }: Props) {
  // routes
  const navigate = useNavigate();

  // providers
  const { setActiveHeadersLinks } = usePageData();
  // redux
  const dispatch = useDispatch();
  const { agentsCards, agentsList, agentLoading, agentsListCount } =
    useAdminSelector();
  // states
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [value, setValue] = useState<any>({
    startDate: moment().subtract(30, "days"),
    endDate: moment(),
  });
  // function
  const UsersListOfItems = async ({ pageNo }: any) => {
    let endDate = moment(value?.endDate);
    endDate.add(23, "hours");
    endDate.add(59, "minutes");
    endDate.add(59, "seconds");
    const obj = {
      startDate: moment(value?.startDate).format("MM-DD-YYYY"),
      endDate: moment(endDate).format("MM-DD-YYYY HH:mm:ss"),
      // endDate: "06-05-2024",
      pageNo: pageNo,
      limit: TablePaginationLimit,
    };
    await dispatch(ActionsGetAgentsDetails(obj));
    await dispatch(ActionsGetAgentsCards());
  };

  const NextClick = (index: any) => {
    console.log(index);
    setCurrentPage(index);
    UsersListOfItems({ pageNo: index });
  };

  const PaginationClick = (page: any) => {
    setCurrentPage(page);
    UsersListOfItems({ pageNo: page });
  };

  const PreviousClick = (index: any) => {
    setCurrentPage(index);
    UsersListOfItems({ pageNo: index });
  };

  const SearchItems = async (query: any) => {
    if (query.length > 0) {
      console.log("first");
      await dispatch(ActionsGetAgentsSearchedDetails(query));
    } else {
      setCurrentPage(1);
      UsersListOfItems({ pageNo: 1 });
    }
  };

  const handleValueChange = (newValue: any) => {
    setValue(newValue);
  };

  // useeffects
  useEffect(() => {
    setCurrentPage(1);
    UsersListOfItems({ pageNo: 1 });
    console.log("first");
  }, [value]);

  return (
    <>
      <div className="py-12 grid grid-cols-4 gap-8">
        <DashboardDataCards
          className={"bg-pink-600 bg-pinkpattern bg-cover bg-no-repeat"}
          text={"Active Agents"}
          numbers={
            agentsCards?.activeAgentsCount ? agentsCards?.activeAgentsCount : 0
          }
          backgroundimage={"cardpattern.svg"}
        />
        <DashboardDataCards
          backgroundimage={"greenpattern.svg "}
          className={"bg-green-600 bg-greenpattern bg-cover bg-no-repeat"}
          text={"Shops Referred"}
          numbers={agentsCards?.totalRefferd ? agentsCards?.totalRefferd : 0}
        />
        <DashboardDataCards
          backgroundimage={"skypattern.svg "}
          className={"bg-sky-600 bg-skypattern bg-cover bg-no-repeat"}
          text={"Total Commissions"}
          numbers={
            agentsCards?.totalCommission ? agentsCards?.totalCommission : 0
          }
        />
      </div>
      <div className="mt-12 ">
        <Table
          nextClick={() => {
            NextClick(
              agentsListCount / TablePaginationLimit > currentPage
                ? currentPage + 1
                : currentPage
            );
          }}
          previousClick={() => {
            PreviousClick(currentPage <= 1 ? 1 : currentPage - 1);
          }}
          paginationClick={PaginationClick}
          totalRecord={agentsListCount}
          limit={TablePaginationLimit}
          currentPage={currentPage}
          label={"Registered Agents"}
          topMargin={"mt-0"}
          color={""}
          labelClass={"border-b-[1px] border-gray-100"}
          actionButton={
            <>
              <div className="flex flex-row justify-end gap-12">
                <div className="border-[1px] border-gray-100 flex flex-row justify-start rounded-md px-4 mb-2">
                  <Image image={"magnifier.svg"} type={"svg"} />
                  <Input
                    onChange={(e: any) => SearchItems(e.target.value)}
                    inputClass={"active:none border-none"}
                    type={"text"}
                    placeholder={"Search..."}
                  />
                </div>
                <span className="flex justify-end xl:gap-4 sm:xl:gap-2 xl:flex-row sm:flex-col  xl:items-center sm:items-end mb-2">
                  <p className="font-inter text-gray-800 font-semibold text-[18px] flex flex-wrap gap-2 relative">
                    <Datepicker
                      toggleClassName="absolute bg-sky-300 rounded-r-lg text-white right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
                      maxDate={new Date()}
                      startFrom={new Date()}
                      inputClassName={" font-500 w-[290px] h-[32px] opacity-0"}
                      containerClassName="text-black absolute -right-[50px] "
                      readOnly={true}
                      showFooter={true}
                      separator={"to"}
                      primaryColor={"violet"}
                      showShortcuts={false}
                      value={value}
                      onChange={handleValueChange}
                    />
                    {moment(value?.startDate).format("DD-MMM-YYYY")}
                    <span>to</span>
                    {moment(value?.endDate).format("DD-MMM-YYYY")}
                  </p>
                  <p className="font-inter text-white font-semibold text-[12px] relative">
                    <Image image={"time.svg"} type={"svg"} />
                  </p>
                </span>
              </div>
            </>
          }
          thead={
            <>
              <thead className="bg-white border-b-[1px] border-gray-100">
                <tr className="">
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-800 capitalize "
                  >
                    Agent ID
                  </th>
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-800 capitalize "
                  >
                    Agent Name
                  </th>
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-800 capitalize "
                  >
                    Registration
                  </th>
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-800 capitalize "
                  >
                    Referral Code
                  </th>
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-800 capitalize "
                  >
                    Shops Referred
                  </th>
                  <th
                    scope="col"
                    className="py-5 px-4 text-[14px] font-semibold tracking-wider text-left  text-gray-800 capitalize "
                  >
                    Commission
                  </th>
                </tr>
              </thead>
            </>
          }
          tbody={
            <>
              <tbody className="bg-white">
                {!agentLoading ? (
                  agentsList ? (
                    agentsList?.map((item: any, index: any) => {
                      return (
                        <>
                          <tr
                            onClick={() => {
                              setActiveHeadersLinks("Agent Profile");
                              setSelectedAgent(item);
                            }}
                            key={index}
                            className="cursor-pointer hover:bg-gray-100  border-b-[1px] border-gray-100"
                          >
                            <td className="py-4 px-4 text-gray-400 text-md font-400  whitespace-nowrap text-left">
                              {item._id ? item._id : ""}
                            </td>
                            <td className="py-4 px-4 text-gray-400  whitespace-nowrap ">
                              {item.phoneNo ? item.phoneNo : ""}
                            </td>
                            <td className="py-4 px-4 text-gray-400 whitespace-nowrap">
                              {item.created
                                ? moment(item.created).format("MMM DD, YYYY")
                                : ""}
                            </td>
                            <td className="py-4 px-4 text-sm font-medium text-gray-400  whitespace-nowrap flex">
                              {item.referralCode ? item.referralCode : "NA"}
                            </td>
                            <td className="py-4 px-4 text-sm font-semibold text-violet-800  whitespace-nowrap ">
                              {item.shopsreffered ? item.shopsreffered : "0"}
                            </td>
                            <td className="py-4 px-4 text-sm font-medium text-gray-400 whitespace-nowrap ">
                              {item.commission ? item.commission : 0}
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <p className="py-4">Data not found</p>
                  )
                ) : (
                  <div className="py-20">
                    <Spinner width={40} height={40} />
                  </div>
                )}
              </tbody>
            </>
          }
        />
      </div>
    </>
  );
}
