export const AdminLoginUrl = `/user/adminLogin`;
export const GetDashboardDataUrl = `/admin/getDetails`;
export const RegisteredShopsListUrl = `/admin/registeredShopList`;
export const RegisteredUserListUrl = `/admin/registeredUserList`;
export const RegisteredAgentListUrl = `/admin/getAgentsDetailedList`;
export const RegisteredAgentCardsUrl = `/admin/getAgentsDetails`;
export const GetNewAgentsDetailsUrl = `/admin/getAgentsDetailedList`;
export const ToGetAgentDetailsUrl = `/admin/agentDetailed`;
export const ToGenerateRefferalCodeUrl = `/admin/generateRefferalCode`;
export const ApproveAgentUrl = `/admin/approvalAgent`;
export const UpdateAgentUrl = `/admin/editAgent`;
export const GetAgentsPaymentListUrl = `/admin/agentPaymentsList`;
export const UpdatePaymentEntryUrl = `/admin/updateAgentPayment`;
export const CompletePaymentUrl = `/admin/paymentCompleted`;
