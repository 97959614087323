import React from "react";
import Image from "../ui-components/Image";

type Props = {
  className: string;
  text: any;
  numbers: number;
  backgroundimage: any;
};

export default function DashboardDataCards({
  className,
  text,
  numbers,
  backgroundimage,
}: Props) {
  return (
    <>
      <div
        className={`${className} h-[220px] relative rounded-lg`}
      >
        <Image className={"z-0"} image={backgroundimage} />
        <div className="absolute bottom-6 right-8 text-center z-20">
          <p className="font-inter text-white font-semibold text-[30px]">
            {numbers}
          </p>
          <p className="font-inter text-white font-semibold text-[12px]">
            {text}
          </p>
        </div>
      </div>
    </>
  );
}
