/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import Image from "../ui-components/Image";
import AsideElement from "./AsideElement";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { usePageData } from "visi/provider/PageDataProvider";
// import clsx from 'clsx'

const AsideDefault: FC = () => {
  // providers
  const { setCurrentUser } = usePageData();
  // url
  const navigate = useNavigate();
  const route = useLocation();
  const Logout = () => {
    setCurrentUser(undefined);
    window.localStorage.clear();
    navigate("/");
  };

  return (
    <>
      {/* begin::Brand */}
      <div className="h-screen">
        <div className="flex justify-center flex-col gap-2 py-2 rounded-md mb-12 mt-4">
          <Link to={"/"}>
            <Image className={""} image={"Group.svg"} type={"svg"} />
          </Link>
        </div>
        <div className="flex justify-between flex-col items-center gap-6 h-[80%]">
          <div className="flex gap-4 flex-col">
            <AsideElement
              text={"Home"}
              icon={"blackhome-2.svg"}
              hoverIcon={"home-2.svg"}
              url={""}
            />
            <AsideElement
              text={"Shops"}
              icon={"blacksubtitle.svg"}
              hoverIcon={"subtitle.svg"}
              url={"shops"}
            />
            <AsideElement
              text={"Customers"}
              icon={"blackabstract-37.svg"}
              hoverIcon={"abstract-37.svg"}
              url={"customers"}
            />
            <AsideElement
              text={"Agents"}
              icon={"adduser.svg"}
              hoverIcon={"blackadduser.svg"}
              url={"agents"}
            />
            <AsideElement
              text={"Payments"}
              icon={"blackmouse-square.svg"}
              hoverIcon={"mouse-square.svg"}
              url={"payments"}
            />
            <AsideElement
              text={"Settings"}
              icon={"rescue.svg"}
              hoverIcon={"rescue.svg"}
              url={"settings"}
            />
          </div>

          <AsideElement
            type="button"
            onClick={() => Logout()}
            className={``}
            text={"Logout"}
            icon={""}
          />
        </div>
      </div>
      {/* end::Brand */}
    </>
  );
};

export { AsideDefault };
